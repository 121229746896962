import React, { Component } from 'react';
import SideNav, { Toggle, Nav, NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';
import '@trendmicro/react-sidenav/dist/react-sidenav.css';
import {NavLink, withRouter} from 'react-router-dom';
// import Logo from './logo.png';
// import SideNav from 'react-side-nav';
class SensFooter extends Component {



render () {

    return (
        <div className='row mx-auto p-1  '>
           
             <img src={'/eulogo.png'} className="footer-img" alt="Logo" />
           
                 

        </div>


);
  

}

}
export default withRouter(SensFooter);
