import React, { useState, Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import axios from '../axios';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Toggle from 'react-toggle';
import AqiHead from './aqiHead';


class HomepageStaticHeader extends Component {
    constructor() {
        super()
        AOS.init({
            duration: 500
        });
        this.state = {
            isOpen: false,
            nowagrAne: [],
            nowagrHum: [],
            nowagrLux: [],
            nowagrPlv1: [],
            nowagrPlv2: [],
            nowagrPlv3: [],
            nowagrPres: [],
            nowagrSoil2: [],
            nowagrTc: [],
            nowagrWv: [],
            nowscpCo2: [],
            nowscpNoise: [],
            nowscpUs: [],
            nowaqi1Ch4: [],
            nowaqi1Co: [],
            nowaqi1Co2: [],
            nowaqi1Hum: [],
            nowaqi1Pm1: [],
            nowaqi1Pm10: [],
            nowaqi1Pm25: [],
            nowaqi1Pres: [],
            nowaqi1Tc: [],
            nowaqi2Hum: [],
            nowaqi2No2: [],
            nowaqi2O3: [],
            nowaqi2So2: [],
            nowaqi2Pm1: [],
            nowaqi2Pm10: [],
            nowaqi2Pm25: [],
            nowaqi2Pres: [],
            nowaqi2Tc: [],
            nowlocations: [],
            nowloc: [],
            nowwasp: [],
            isLoaded: false
        }
    }

    toggle = () => {
        this.setState({ isOpen: true });

    }


    callSensors = () => {

        axios.get('/now/agr/ane').then((data) => {
            this.setState({ nowagrAne: data.data })
            console.log(data.data)
        }).catch(console.log);
        axios.get('/now/agr/hum').then((data) => {
            this.setState({ nowagrHum: data.data })
            console.log(data.data)
        }).catch(console.log);
        axios.get('/now/agr/lux').then((data) => {
            this.setState({ nowagrLux: data.data })
            console.log(data.data)
        }).catch(console.log);
        axios.get('/now/agr/plv1').then((data) => {
            this.setState({ nowagrPlv1: data.data })
            console.log(data.data)
        }).catch(console.log);
        axios.get('/now/agr/plv2').then((data) => {
            this.setState({ nowagrPlv2: data.data })
            console.log(data.data)
        }).catch(console.log);
        axios.get('/now/agr/plv3').then((data) => {
            this.setState({ nowagrPlv3: data.data })
            console.log(data.data)
        }).catch(console.log);
        axios.get('/now/agr/pres').then((data) => {
            this.setState({ nowagrPres: data.data })
            console.log(data.data)
        }).catch(console.log);
        axios.get('/now/agr/soil2').then((data) => {
            this.setState({ nowagrSoil2: data.data })
            console.log(data.data)
        }).catch(console.log);
        axios.get('/now/agr/tc').then((data) => {
            this.setState({ nowagrTc: data.data })
            console.log(data.data)
        }).catch(console.log);
        axios.get('/now/agr/wv').then((data) => {
            this.setState({ nowagrWv: data.data })
            console.log(data.data)
        }).catch(console.log);
        axios.get('/now/scp/co2').then((data) => {
            this.setState({ nowscpCo2: data.data })
            console.log(data.data)
        }).catch(console.log);
        axios.get('/now/scp/noise').then((data) => {
            this.setState({ nowscpNoise: data.data })
            console.log(data.data)
        }).catch(console.log);
        axios.get('/now/scp/us').then((data) => {
            this.setState({ nowscpUs: data.data })
            console.log(data.data)
        }).catch(console.log);
        axios.get('/now/aqi1/ch4').then((data) => {
            this.setState({ nowaqi1Ch4: data.data })
            console.log(data.data)
        }).catch(console.log);
        axios.get('/now/aqi1/co').then((data) => {
            this.setState({ nowaqi1Co: data.data })
            console.log(data.data)
        }).catch(console.log);
        axios.get('/now/aqi1/co2').then((data) => {
            this.setState({ nowaqi1Co2: data.data })
            console.log(data.data)
        }).catch(console.log);
        axios.get('/now/aqi1/hum').then((data) => {
            this.setState({ nowaqi1Hum: data.data })
            console.log(data.data)
        }).catch(console.log);
        axios.get('/now/aqi1/pm1').then((data) => {
            this.setState({ nowaqi1Pm1: data.data })
            console.log(data.data)
        }).catch(console.log);
        axios.get('/now/aqi1/pm10').then((data) => {
            this.setState({ nowaqi1Pm10: data.data })
            console.log(data.data)
        }).catch(console.log);
        axios.get('/now/aqi1/pm2-5').then((data) => {
            this.setState({ nowaqi1Pm25: data.data })
            console.log(data.data)
        }).catch(console.log);
        axios.get('/now/aqi1/pres').then((data) => {
            this.setState({ nowaqi1Pres: data.data })
            console.log(data.data)
        }).catch(console.log);
        axios.get('/now/aqi1/tc').then((data) => {
            this.setState({ nowaqi1Tc: data.data })
            console.log(data.data)
        }).catch(console.log);
        axios.get('/now/aqi2/no2').then((data) => {
            this.setState({ nowaqi2No2: data.data })
            console.log(data.data)
        }).catch(console.log);
        axios.get('/now/aqi2/o3').then((data) => {
            this.setState({ nowaqi2O3: data.data })
            console.log(data.data)
        }).catch(console.log);
        axios.get('/now/aqi2/pm1').then((data) => {
            this.setState({ nowaqi2Pm1: data.data })
            console.log(data.data)
        }).catch(console.log);
        axios.get('/now/aqi2/pm10').then((data) => {
            this.setState({ nowaqi2Pm10: data.data })
            console.log(data.data)
        }).catch(console.log);
        axios.get('/now/aqi2/pm2-5').then((data) => {
            this.setState({ nowaqi2Pm25: data.data })
            console.log(data.data)
        }).catch(console.log);
        axios.get('/now/aqi2/pres').then((data) => {
            this.setState({ nowaqi2Pres: data.data })
            console.log(data.data)
        }).catch(console.log);
        axios.get('/now/aqi2/tc').then((data) => {
            this.setState({ nowaqi2Tc: data.data })
            console.log(data.data)
        }).catch(console.log);
        axios.get('/now/aqi2/so2').then((data) => {
            this.setState({ nowaqi2So2: data.data })
            console.log(data.data)
        }).catch(console.log);
        axios.get('/now/aqi2/hum').then((data) => {
            this.setState({ nowaqi2Hum: data.data })
            console.log(data.data)
        }).catch(console.log);
    }



    pageReload = () => {
        setInterval(function() {
            window.location.reload();
        }, 180000);
    }

    componentDidMount() {
        this.pageReload();
        window.scrollTo(0, 0);
        this.callSensors();

        AOS.init();
        axios.get('/nowlocations')
            // .then(res => res.json())
            .then((data) => {
                this.setState({ nowlocations: [data.data[0]] })
                //  console.log(data.data)
            }
            )

        axios.get('/nowlocations')
            // .then(res => res.json())
            .then((data) => {
                this.setState({ nowloc: data.data })
                //  console.log(data.data)
            }
            )


        axios.get('/nowwasp')
            // .then(res => res.json())
            .then((data) => {
                this.setState({ nowwasp: data.data, isLoaded: true })
                // console.log(data.data)
            }
            )


    }
    handleTofuChange = () => {
        this.props.history.push('/');
    }


    render() {



        return (
            <React.Fragment>

                {this.state.isLoaded ? null : <div className="container"><div className="loader">Loading...</div></div>}

                <div className="row pl-lg-5 pr-lg-5 pl-3 pr-1" aos-init aos-animate >
                    {
                        this.state.nowwasp.map((nowwasp) => (

                            <div className="col-lg-6 col-sm-12 " data-aos="flip-right" data-aos-easing="linear" >
                                <div className="libelium-box p-3" >
                                    <h3> {nowwasp.id_nowwasp == 'ADKGEN_AGR' ? this.props.label.smart_agr_pro : nowwasp.id_nowwasp
                                        && nowwasp.id_nowwasp == 'ADKGEN_SCP' ? this.props.label.smart_city_pro : nowwasp.id_nowwasp
                                            && nowwasp.id_nowwasp == 'AQI01' ? this.props.label.smart_env_pro_d1 : nowwasp.id_nowwasp
                                                && nowwasp.id_nowwasp == 'AQI02' ? this.props.label.smart_env_pro_d2 : nowwasp.id_nowwasp}</h3>

                                    {this.state.nowlocations.map((nowlocations) => (
                                        <div className="row">
                                            {this.state.nowloc.map((loc) => (
                                                <div className="col-12 col-sm-12 text-left">
                                                    {nowwasp.MeshliumID == loc.MeshliumID ? `${this.props.label.location}: ${loc.LongDSC} ` : ''}
                                                </div>
                                            ))}
                                            <div className="col-12 col-sm-12 text-left pb-2 box-shadow-dn">
                                                {nowwasp.id_nowwasp == 'ADKGEN_AGR' && (`${this.props.label.last_update}: ${this.state.nowagrAne.timestamp && this.state.nowagrAne.timestamp.slice(0,5)}`)}
                                                {nowwasp.id_nowwasp == 'ADKGEN_SCP' && (`${this.props.label.last_update}: ${this.state.nowscpUs.timestamp  && this.state.nowscpUs.timestamp.slice(0,5)}`)}
                                                {nowwasp.id_nowwasp == 'AQI01' && (`${this.props.label.last_update}: ${this.state.nowaqi1Tc.timestamp  && this.state.nowaqi1Tc.timestamp.slice(0,5)}`)}
                                                {nowwasp.id_nowwasp == 'AQI02' && (`${this.props.label.last_update}: ${this.state.nowaqi2Tc.timestamp  && this.state.nowaqi2Tc.timestamp.slice(0,5)}`)}

                                            </div>

                                            <div className="w-100 height-box overflow-auto">
                                                {nowwasp.id_nowwasp == 'ADKGEN_AGR' ? (


                                                    <React.Fragment>

                                                        <NavLink to={'tc/ADKGEN_AGR'} >
                                                            <div className="row w-100 main-hover ml-0  ">
                                                                <div className="col-lg-8 col-6 p-2 text-right align-middle ">   {this.props.label.temperature} </div>
                                                                <div className="col-lg-4 col-6 p-2 bg-secondary text-light  text-left align-middle">  {(this.state.nowagrTc.value == 'undefined' || this.state.nowagrTc.value == null) ? 'N/A' : (this.state.nowagrTc.value.slice(0, 5) + ' ºC')} </div>
                                                            </div>
                                                        </NavLink>

                                                        <NavLink to={'wv/ADKGEN_AGR'} >
                                                            <div className="row w-100 main-hover ml-0  ">
                                                                <div className="col-lg-8 col-6 p-2 text-right align-middle ">   {this.props.label.wind_vane} </div>
                                                                <div className="col-lg-4 col-6 p-2 bg-secondary text-light  text-left align-middle">  {this.state.nowagrWv.value.slice(0, 5) + ' Direction'} </div>
                                                            </div>
                                                        </NavLink>

                                                        <NavLink to={'ane/ADKGEN_AGR'} >
                                                            <div className="row w-100 main-hover ml-0  ">
                                                                <div className="col-lg-8 col-6 p-2 text-right align-middle "> {this.props.label.anemometer}</div>
                                                                <div className="col-lg-4 col-6 p-2 bg-secondary text-light  text-left align-middle"> {this.state.nowagrAne.value.slice(0, 5) + ' km/h'} </div>
                                                            </div>
                                                        </NavLink>

                                                        <NavLink to={'hum/ADKGEN_AGR'} >
                                                            <div className="row w-100 main-hover ml-0  ">
                                                                <div className="col-lg-8 col-6 p-2 text-right align-middle "> {this.props.label.humidity}</div>
                                                                <div className="col-lg-4 col-6 p-2 bg-secondary text-light  text-left align-middle">  {this.state.nowagrHum.value.slice(0, 5) + ' %RH'} </div>
                                                            </div>
                                                        </NavLink>

                                                        <NavLink to={'lux/ADKGEN_AGR'} >
                                                            <div className="row w-100 main-hover ml-0  ">
                                                                <div className="col-lg-8 col-6 p-2 text-right align-middle "> {this.props.label.luminosity}</div>
                                                                <div className="col-lg-4 col-6 p-2 bg-secondary text-light  text-left align-middle">  {this.state.nowagrLux.value.slice(0, 5) + ' luxes'} </div>
                                                            </div>
                                                        </NavLink>

                                                        <NavLink to={'plv1/ADKGEN_AGR'} >
                                                            <div className="row w-100 main-hover ml-0  ">
                                                                <div className="col-lg-8 col-6 p-2 text-right align-middle "> {this.props.label.pluviometer} {this.props.label.current_hour}</div>
                                                                <div className="col-lg-4 col-6 p-2 bg-secondary text-light  text-left align-middle">  {this.state.nowagrPlv1.value.slice(0, 5) + ' mm'} </div>
                                                            </div>
                                                        </NavLink>

                                                        <NavLink to={'plv2/ADKGEN_AGR'} >
                                                            <div className="row w-100 main-hover ml-0  ">
                                                                <div className="col-lg-8 col-6 p-2 text-right align-middle "> {this.props.label.pluviometer} {this.props.label.previous_hour}</div>
                                                                <div className="col-lg-4 col-6 p-2 bg-secondary text-light  text-left align-middle">  {this.state.nowagrPlv2.value.slice(0, 5) + ' mm/h'} </div>
                                                            </div>
                                                        </NavLink>

                                                        <NavLink to={'plv3/ADKGEN_AGR'} >
                                                            <div className="row w-100 main-hover ml-0  ">
                                                                <div className="col-lg-8 col-6 p-2 text-right align-middle "> {this.props.label.pluviometer} {this.props.label.last_24}</div>
                                                                <div className="col-lg-4 col-6 p-2 bg-secondary text-light  text-left align-middle">  {this.state.nowagrPlv3.value.slice(0, 5) + ' mm/day'} </div>
                                                            </div>
                                                        </NavLink>

                                                        <NavLink to={'pres/ADKGEN_AGR'} >
                                                            <div className="row w-100 main-hover ml-0  ">
                                                                <div className="col-lg-8 col-6 p-2 text-right align-middle ">   {this.props.label.pressure}</div>
                                                                <div className="col-lg-4 col-6 p-2 bg-secondary text-light  text-left align-middle">  {this.state.nowagrPres.value.slice(0, 5) + ' Pascales'} </div>
                                                            </div>
                                                        </NavLink>

                                                        <NavLink to={'soil2/ADKGEN_AGR'} >
                                                            <div className="row w-100 main-hover ml-0  ">
                                                                <div className="col-lg-8 col-6 p-2 text-right align-middle "> {this.props.label.soil_mosture_watermark2} </div>
                                                                <div className="col-lg-4 col-6 p-2 bg-secondary text-light  text-left align-middle">  {this.state.nowagrSoil2.value.slice(0, 5) + ' Frequency'} </div>
                                                            </div>
                                                        </NavLink>







                                                    </React.Fragment>
                                                ) : ''}


                                                {nowwasp.id_nowwasp == 'ADKGEN_SCP' ? (


                                                    <React.Fragment>

                                                        <NavLink to={'co2/ADKGEN_SCP'} >
                                                            <div className="row w-100 main-hover ml-0  ">
                                                                <div className="col-lg-8 col-6 p-2 text-right align-middle ">   {this.props.label.carbon_dioxide} </div>
                                                                <div className="col-lg-4 col-6 p-2 bg-secondary text-light  text-left align-middle">  {(this.state.nowscpCo2.value == 'undefined' || this.state.nowscpCo2.value == null) ? 'N/A' : (this.state.nowscpCo2.value.slice(0, 5) + ' ppm')} </div>
                                                            </div>
                                                        </NavLink>

                                                        <NavLink to={'noise/ADKGEN_SCP'} >
                                                            <div className="row w-100 main-hover ml-0  ">
                                                                <div className="col-lg-8 col-6 p-2 text-right align-middle ">   {this.props.label.noise_level} </div>
                                                                <div className="col-lg-4 col-6 p-2 bg-secondary text-light  text-left align-middle">  {this.state.nowscpNoise.value.slice(0, 5) + ' dBA'} </div>
                                                            </div>
                                                        </NavLink>

                                                        <NavLink to={'us/ADKGEN_SCP'} >
                                                            <div className="row w-100 main-hover ml-0  ">
                                                                <div className="col-lg-8 col-6 p-2 text-right align-middle "> {this.props.label.ultrasound}</div>
                                                                <div className="col-lg-4 col-6 p-2 bg-secondary text-light  text-left align-middle"> {this.state.nowscpUs.value.slice(0, 5) + ' cm'} </div>
                                                            </div>
                                                        </NavLink>

                                                    </React.Fragment>
                                                ) : ''}



                                                {nowwasp.id_nowwasp == 'AQI01' ? (


                                                    <React.Fragment>

                                                        <NavLink to={'ch4/AQI01'} >
                                                            <div className="row w-100 main-hover ml-0  ">
                                                                <div className="col-lg-8 col-6 p-2 text-right align-middle ">   {this.props.label.methane} </div>
                                                                <div className="col-lg-4 col-6 p-2 bg-secondary text-light  text-left align-middle">  {(this.state.nowaqi1Ch4.value == 'undefined' || this.state.nowaqi1Ch4.value == null) ? 'N/A' : (this.state.nowaqi1Ch4.value.slice(0, 5) + ' % LEL')} </div>
                                                            </div>
                                                        </NavLink>

                                                        <NavLink to={'co/AQI01'} >
                                                            <div className="row w-100 main-hover ml-0  ">
                                                                <div className="col-lg-8 col-6 p-2 text-right align-middle ">   {this.props.label.carbon_monoxide} </div>
                                                                <div className="col-lg-4 col-6 p-2 bg-secondary text-light  text-left align-middle">  {(this.state.nowaqi1Co.value == 'undefined' || this.state.nowaqi1Co.value == null) ? 'N/A' : (this.state.nowaqi1Co.value.slice(0, 5) + ' ppm')} </div>
                                                            </div>
                                                        </NavLink>

                                                        <NavLink to={'co2/AQI01'} >
                                                            <div className="row w-100 main-hover ml-0  ">
                                                                <div className="col-lg-8 col-6 p-2 text-right align-middle "> {this.props.label.carbon_dioxide}</div>
                                                                <div className="col-lg-4 col-6 p-2 bg-secondary text-light  text-left align-middle"> {(this.state.nowaqi1Co2.value == 'undefined' || this.state.nowaqi1Co2.value == null) ? 'N/A' : (this.state.nowaqi1Co2.value.slice(0, 5) + ' ppm')} </div>
                                                            </div>
                                                        </NavLink>

                                                        <NavLink to={'hum/AQI01'} >
                                                            <div className="row w-100 main-hover ml-0  ">
                                                                <div className="col-lg-8 col-6 p-2 text-right align-middle "> {this.props.label.humidity}</div>
                                                                <div className="col-lg-4 col-6 p-2 bg-secondary text-light  text-left align-middle">  {(this.state.nowaqi1Hum.value == 'undefined' || this.state.nowaqi1Hum.value == null) ? 'N/A' : (this.state.nowaqi1Hum.value.slice(0, 5) + ' %RH')} </div>
                                                            </div>
                                                        </NavLink>

                                                        <NavLink to={'pres/AQI01'} >
                                                            <div className="row w-100 main-hover ml-0  ">
                                                                <div className="col-lg-8 col-6 p-2 text-right align-middle "> {this.props.label.pressure} {this.props.label.last_24}</div>
                                                                <div className="col-lg-4 col-6 p-2 bg-secondary text-light  text-left align-middle">  {(this.state.nowaqi1Pres.value == 'undefined' || this.state.nowaqi1Pres.value == null) ? 'N/A' : (this.state.nowaqi1Pres.value.slice(0, 5) + ' Pascales')} </div>
                                                            </div>
                                                        </NavLink>

                                                        <NavLink to={'tc/AQI01'} >
                                                            <div className="row w-100 main-hover ml-0  ">
                                                                <div className="col-lg-8 col-6 p-2 text-right align-middle ">   {this.props.label.temperature}</div>
                                                                <div className="col-lg-4 col-6 p-2 bg-secondary text-light  text-left align-middle">  {(this.state.nowaqi1Tc.value == 'undefined' || this.state.nowaqi1Tc.value == null) ? 'N/A' : (this.state.nowaqi1Tc.value.slice(0, 5) + ' ºC')} </div>
                                                            </div>
                                                        </NavLink>

                                                    </React.Fragment>
                                                ) : ''}



                                                {nowwasp.id_nowwasp == 'AQI02' ? (


                                                    <React.Fragment>

                                                        <NavLink to={'hum/AQI02'} >
                                                            <div className="row w-100 main-hover ml-0  ">
                                                                <div className="col-lg-8 col-6 p-2 text-right align-middle "> {this.props.label.humidity}</div>
                                                                <div className="col-lg-4 col-6 p-2 bg-secondary text-light  text-left align-middle">  {(this.state.nowaqi2Hum.value == 'undefined' || this.state.nowaqi2Hum.value == null) ? 'N/A' : (this.state.nowaqi2Hum.value.slice(0, 5) + ' %RH')} </div>
                                                            </div>
                                                        </NavLink>

                                                        <NavLink to={'no2/AQI02'} >
                                                            <div className="row w-100 main-hover ml-0  ">
                                                                <div className="col-lg-8 col-6 p-2 text-right align-middle ">   {this.props.label.nitrogen_dioxide} </div>
                                                                <div className="col-lg-4 col-6 p-2 bg-secondary text-light  text-left align-middle">  {(this.state.nowaqi2No2.value == 'undefined' || this.state.nowaqi2No2.value == null) ? 'N/A' : (this.state.nowaqi2No2.value.slice(0, 5) + ' ppm')} </div>
                                                            </div>
                                                        </NavLink>

                                                        <NavLink to={'o3/AQI02'} >
                                                            <div className="row w-100 main-hover ml-0  ">
                                                                <div className="col-lg-8 col-6 p-2 text-right align-middle ">   {this.props.label.ozone} </div>
                                                                <div className="col-lg-4 col-6 p-2 bg-secondary text-light  text-left align-middle">  {(this.state.nowaqi2O3.value == 'undefined' || this.state.nowaqi2O3.value == null) ? 'N/A' : (this.state.nowaqi2O3.value.slice(0, 5) + ' ppm')} </div>
                                                            </div>
                                                        </NavLink>

                                                        <NavLink to={'pm1/AQI02'} >
                                                            <div className="row w-100 main-hover ml-0  ">
                                                                <div className="col-lg-8 col-6 p-2 text-right align-middle "> {this.props.label.particle_matter_1}</div>
                                                                <div className="col-lg-4 col-6 p-2 bg-secondary text-light  text-left align-middle">  {(this.state.nowaqi2Pm1.value == 'undefined' || this.state.nowaqi2Pm1 == null) ? 'N/A' : (this.state.nowaqi2Pm1.value.slice(0, 5) + ' μg/m3')} </div>
                                                            </div>
                                                        </NavLink>

                                                        <NavLink to={'pm10/AQI02'} >
                                                            <div className="row w-100 main-hover ml-0  ">
                                                                <div className="col-lg-8 col-6 p-2 text-right align-middle "> {this.props.label.particle_matter_10}</div>
                                                                <div className="col-lg-4 col-6 p-2 bg-secondary text-light  text-left align-middle"> {(this.state.nowaqi2Pm10.value == 'undefined' || this.state.nowaqi2Pm10 == null) ? 'N/A' : (this.state.nowaqi2Pm10.value.slice(0, 5) + ' μg/m3')}</div>
                                                            </div>
                                                        </NavLink>

                                                        <NavLink to={'pm25/AQI02'} >
                                                            <div className="row w-100 main-hover ml-0  ">
                                                                <div className="col-lg-8 col-6 p-2 text-right align-middle "> {this.props.label.particle_matter_25} </div>
                                                                <div className="col-lg-4 col-6 p-2 bg-secondary text-light  text-left align-middle">  {(this.state.nowaqi2Pm25.value == 'undefined' || this.state.nowaqi2Pm25 == null) ? 'N/A' : (this.state.nowaqi2Pm25.value.slice(0, 5) + ' μg/m3')} </div>
                                                            </div>
                                                        </NavLink>

                                                        <NavLink to={'so2/AQI02'} >
                                                            <div className="row w-100 main-hover ml-0  ">
                                                                <div className="col-lg-8 col-6 p-2 text-right align-middle "> {this.props.label.sulfur_dioxide}</div>
                                                                <div className="col-lg-4 col-6 p-2 bg-secondary text-light  text-left align-middle"> {(this.state.nowaqi2So2.value == 'undefined' || this.state.nowaqi2So2.value == null) ? 'N/A' : (this.state.nowaqi2So2.value.slice(0, 5) + ' ppm')} </div>
                                                            </div>
                                                        </NavLink>

                                                        <NavLink to={'pres/AQI02'} >
                                                            <div className="row w-100 main-hover ml-0  ">
                                                                <div className="col-lg-8 col-6 p-2 text-right align-middle "> {this.props.label.pressure}</div>
                                                                <div className="col-lg-4 col-6 p-2 bg-secondary text-light  text-left align-middle">  {(this.state.nowaqi2Pres.value == 'undefined' || this.state.nowaqi2Pres.value == null) ? 'N/A' : (this.state.nowaqi2Pres.value.slice(0, 5) + ' Pascales')} </div>
                                                            </div>
                                                        </NavLink>

                                                        <NavLink to={'tc/AQI02'} >
                                                            <div className="row w-100 main-hover ml-0  ">
                                                                <div className="col-lg-8 col-6 p-2 text-right align-middle ">   {this.props.label.temperature}</div>
                                                                <div className="col-lg-4 col-6 p-2 bg-secondary text-light  text-left align-middle">  {(this.state.nowaqi2Tc.value == 'undefined' || this.state.nowaqi2Tc.value == null) ? 'N/A' : (this.state.nowaqi2Tc.value.slice(0, 3) + ' ºC')} </div>
                                                            </div>
                                                        </NavLink>


                                                    </React.Fragment>
                                                ) : ''}



                                            </div>
                                        </div>
                                    ))}


                                </div>
                            </div>


                        ))}

                </div>
            </React.Fragment>

        );

    }

}

export default withRouter(HomepageStaticHeader);