import React, { useState, useEffect } from 'react';
import './Login.scss';
import axios from './../axios';

import { withRouter } from 'react-router-dom';

const Login = (props) => {

    let [ data, setData ] = useState({});
    let [ err, setErr ] = useState(false);

    useEffect(() => {
        if(window.localStorage.getItem('token')){
            props.history.goBack();
        }
    }, [])

    const email = (e) => {
        let d = Object.assign({}, data);
        d.email = e.target.value;
        setData(d);
    }

    const pass = (e) => {
        let d = Object.assign({}, data);
        d.pass = e.target.value;
        setData(d);
    }

    const submit = (e) => {
        e.preventDefault();
        axios.post('/login', data).then(data => {
            console.log('loginData', data.data);
            if(data.data.token) {
                window.localStorage.setItem('token', data.data.token);
                props.history.push('/batteries');
                window.location.reload();
            } else {
                setErr(true);
            }

        }).catch(err => {
            console.log(err);
            setErr(true);
        })
        // if(data.password == '1234Opuntia' && data.username == 'Opuntia'){
        //     localStorage.setItem('t13', 'arsc-1230_asas-assa-a')
        //     props.history.push('/batteries')
        // }
        // else
        // {
        //     setErr(true);
        // }
    }

    return (
        <div className="wrapper fadeInDown">
            <div id="formContent">
                <div className="fadeIn first">
                    <img src="/smartlogo.png" id="icon" alt="User Icon" />
                </div>
                <form onSubmit = { submit }>
                    { err && 'Authentication Failed...'}
                    <input type="email" onChange = { email} id="login" className="fadeIn second" name="login" placeholder="username" required/>
                    <input type="password" onChange = { pass} id="password" className="fadeIn third" name="login" placeholder="password" required/>
                    <input type="submit" className="fadeIn fourth" value="Log In"/>
                </form>
            </div>
        </div>
    );
};

export default withRouter(Login);