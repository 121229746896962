import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import axios from '../axios';
import AOS from 'aos';
import 'aos/dist/aos.css';
import htmlToImage from 'html-to-image';
import { Bar } from 'react-chartjs-2';
import Toggle from 'react-toggle';
import AqiHead from './aqiHead';
import fbshare from '../icons/fb.png';
import Scientific from './scientific.js';
class Homepage extends Component {
    constructor(props) {
        super(props)
        AOS.init({
            duration: 500
        });
        this.state = {
            isOpen: false,
            graphPm25: [],
            graphPm10: [],
            graphCO2: [],
            graphSO2: [],
            graphCO: [],
            graphO3: [],
            graphNO2: [],
            isLoaded: false,
      aqi2Pm10: [],
      aqi2Pm25: [],
      aqiPm25: [],
      aqiPm10: [],
      aqiSo2: [],
      aqiO3: [],
      aqiCo2: [],
      aqiCo: [],
      aqiNo2: [],
      agrAne: [],
      agrHum: [],
      agrPres: [],
      agrTc: [],
      agrWv: [],
      aqi2Tc: [],
      aqi1Co2: [],
      aqi1Co: [],
      aqi2O3: [],
      aqi2No2: [],
      aqi2So2: [],
            autoRefresh: false,
            togglebutton: false,

            nowagrAne: [],
            nowagrHum: [],
            nowagrLux: [],
            nowagrPlv1: [],
            nowagrPlv2: [],
            nowagrPlv3: [],
            nowagrPres: [],
            nowagrSoil2: [],
            nowagrTc: [],
            nowagrWv: [],
            nowscpCo2: [],
            nowscpNoise: [],
            nowscpUs: [],
            nowaqi1Ch4: [],
            nowaqi1Co: [],
            nowaqi1Co2: [],
            nowaqi1Hum: [],
            nowaqi1Pm1: [],
            nowaqi1Pm10: [],
            nowaqi1Pm25: [],
            nowaqi1Pres: [],
            nowaqi1Tc: [],
            nowaqi2Hum: [],
            nowaqi2No2: [],
            nowaqi2O3: [],
            nowaqi2So2: [],
            nowaqi2Pm1: [],
            nowaqi2Pm10: [],
            nowaqi2Pm25: [],
            nowaqi2Pres: [],
            nowaqi2Tc: [],
            nowlocations: [],
            nowloc: [],
            nowwasp: [],

            // buttonState: false
        }
    }

    toggle = () => {
        this.setState({ isOpen: true });

    }
    autorefresh = () => {
        this.setState({autoRefresh: !this.state.autoRefresh});
        if (this.state.autoRefresh == true ) { (
      this.interval = setInterval(()=> {
         this.apiCalls();

            } , 180000)
        );
    } else {
        clearInterval(this.interval);
    } }
    handleTofuChange = () => {
        // this.props.history.push('/scientific');
        this.setState({ togglebutton: !this.state.togglebutton});
    }
    // pageReload = () => {
    //     setInterval(function () {
    //         window.location.reload();
    //     }, 180000);
    // }
apiCalls = () => {
    axios.get('/hravg/?wasp=AQI02&sens=PM2_5').then((data) => {
        this.setState({ graphPm25: data.data })
    }).catch();
    axios.get('/hravg/?wasp=AQI02&sens=PM10').then((data) => {
        this.setState({ graphPm10: data.data })
    }).catch();
    axios.get('/hravg/?wasp=AQI01&sens=CO2').then((data) => {
        this.setState({ graphCO2: data.data })
    }).catch();
    axios.get('/hravg/?wasp=AQI01&sens=CO').then((data) => {
        this.setState({ graphCO: data.data })
    }).catch();
    axios.get('/hravg/?wasp=AQI02&sens=O3').then((data) => {
        this.setState({ graphO3: data.data })
    }).catch();
    axios.get('/hravg/?wasp=AQI02&sens=NO2').then((data) => {
        this.setState({ graphNO2: data.data })
    }).catch();
    axios.get('/hravg/?wasp=AQI02&sens=SO2').then((data) => {
        this.setState({ graphSO2: data.data })
    }).catch();
      
      axios.get('/aqi/?wasp=AQI02&sens=PM2_5').then((data) => {
        this.setState({ aqiPm25: data.data })
      }).catch();
      axios.get('/aqi/?wasp=AQI02&sens=PM10').then((data) => {
        this.setState({ aqiPm10: data.data })
      }).catch();
      axios.get('/aqi/?wasp=AQI01&sens=CO2').then((data) => {
        this.setState({ aqiCo2: data.data })
      }).catch();
      axios.get('/aqi/?wasp=AQI01&sens=CO').then((data) => {
        this.setState({ aqiCo: data.data })
      }).catch();
      axios.get('/aqi/?wasp=AQI02&sens=O3').then((data) => {
        this.setState({ aqiO3: data.data })
      }).catch();
      axios.get('/aqi/?wasp=AQI02&sens=NO2').then((data) => {
        this.setState({ aqiNo2: data.data })
      }).catch();
      axios.get('/aqi/?wasp=AQI02&sens=SO2').then((data) => {
        this.setState({ aqiSo2: data.data })
      }).catch();
      axios.get('/now/agr/ane').then((data) => {
        this.setState({ agrAne: data.data })
      }).catch();
      axios.get('/now/agr/hum').then((data) => {
        this.setState({ agrHum: data.data })
      }).catch();
      axios.get('/now/agr/pres').then((data) => {
        this.setState({ agrPres: data.data })
      }).catch();
      axios.get('/now/aqi1/co').then((data) => {
        this.setState({ aqi1Co: data.data })
      }).catch();
      axios.get('/now/agr/tc').then((data) => {
        this.setState({ agrTc: data.data })
      }).catch();
      axios.get('/now/agr/wv').then((data) => {
        this.setState({ agrWv: data.data })
      }).catch();
      axios.get('/now/aqi2/no2').then((data) => {
        this.setState({ aqi2No2: data.data })
      }).catch();
      axios.get('/now/aqi2/pm10').then((data) => {
        this.setState({ aqi2Pm10: data.data })
      }).catch();
      axios.get('/now/aqi2/pm2-5').then((data) => {
        this.setState({ aqi2Pm25: data.data })
      }).catch();
      axios.get('/now/aqi2/o3').then((data) => {
        this.setState({ aqi2O3: data.data })
      }).catch();
      axios.get('/now/aqi2/so2').then((data) => {
        this.setState({ aqi2So2: data.data })
      }).catch();
      axios.get('/now/aqi2/tc').then((data) => {
        this.setState({ aqi2Tc: data.data })
      }).catch();
      axios.get('/now/aqi1/co2').then((data) => {
        this.setState({ aqi1Co2: data.data })
      }).catch();


axios.get('/locations').then((data) => {this.setState({ nowlocations: [data.data[0]] })});
axios.get('/locations').then((data) => {this.setState({ nowloc: data.data })});
axios.get('/wasp').then((data) => {this.setState({ nowwasp: data.data, isLoaded: true })});
axios.get('/now/agr/ane').then((data) => {
    this.setState({ nowagrAne: data.data });
    console.log(data.data);
}).catch(console.log);
axios.get('/now/agr/hum').then((data) => {
    this.setState({ nowagrHum: data.data })
    console.log(data.data)
}).catch(console.log);
axios.get('/now/agr/lux').then((data) => {
    this.setState({ nowagrLux: data.data })
    console.log(data.data)
}).catch(console.log);
axios.get('/now/agr/plv1').then((data) => {
    this.setState({ nowagrPlv1: data.data })
    console.log(data.data)
}).catch(console.log);
axios.get('/now/agr/plv2').then((data) => {
    this.setState({ nowagrPlv2: data.data })
    console.log(data.data)
}).catch(console.log);
axios.get('/now/agr/plv3').then((data) => {
    this.setState({ nowagrPlv3: data.data })
    console.log(data.data)
}).catch(console.log);
axios.get('/now/agr/pres').then((data) => {
    this.setState({ nowagrPres: data.data })
    console.log(data.data)
}).catch(console.log);
axios.get('/now/agr/soil2').then((data) => {
    this.setState({ nowagrSoil2: data.data })
    console.log(data.data)
}).catch(console.log);
axios.get('/now/agr/tc').then((data) => {
    this.setState({ nowagrTc: data.data })
    console.log(data.data)
}).catch(console.log);
axios.get('/now/agr/wv').then((data) => {
    this.setState({ nowagrWv: data.data })
    console.log(data.data)
}).catch(console.log);
axios.get('/now/scp/co2').then((data) => {
    this.setState({ nowscpCo2: data.data })
    console.log(data.data)
}).catch(console.log);
axios.get('/now/scp/noise').then((data) => {
    this.setState({ nowscpNoise: data.data })
    console.log(data.data)
}).catch(console.log);
axios.get('/now/scp/us').then((data) => {
    this.setState({ nowscpUs: data.data })
    console.log(data.data)
}).catch(console.log);
axios.get('/now/aqi1/ch4').then((data) => {
    this.setState({ nowaqi1Ch4: data.data })
    console.log(data.data)
}).catch(console.log);
axios.get('/now/aqi1/co').then((data) => {
    this.setState({ nowaqi1Co: data.data })
    console.log(data.data)
}).catch(console.log);
axios.get('/now/aqi1/co2').then((data) => {
    this.setState({ nowaqi1Co2: data.data })
    console.log(data.data)
}).catch(console.log);
axios.get('/now/aqi1/hum').then((data) => {
    this.setState({ nowaqi1Hum: data.data })
    console.log(data.data)
}).catch(console.log);
axios.get('/now/aqi1/pm1').then((data) => {
    this.setState({ nowaqi1Pm1: data.data })
    console.log(data.data)
}).catch(console.log);
axios.get('/now/aqi1/pm10').then((data) => {
    this.setState({ nowaqi1Pm10: data.data })
    console.log(data.data)
}).catch(console.log);
axios.get('/now/aqi1/pm2-5').then((data) => {
    this.setState({ nowaqi1Pm25: data.data })
    console.log(data.data)
}).catch(console.log);
axios.get('/now/aqi1/pres').then((data) => {
    this.setState({ nowaqi1Pres: data.data })
    console.log(data.data)
}).catch(console.log);
axios.get('/now/aqi1/tc').then((data) => {
    this.setState({ nowaqi1Tc: data.data })
    console.log(data.data)
}).catch(console.log);
axios.get('/now/aqi2/no2').then((data) => {
    this.setState({ nowaqi2No2: data.data })
    console.log(data.data)
}).catch(console.log);
axios.get('/now/aqi2/o3').then((data) => {
    this.setState({ nowaqi2O3: data.data })
    console.log(data.data)
}).catch(console.log);
axios.get('/now/aqi2/pm1').then((data) => {
    this.setState({ nowaqi2Pm1: data.data })
    console.log(data.data)
}).catch(console.log);
axios.get('/now/aqi2/pm10').then((data) => {
    this.setState({ nowaqi2Pm10: data.data })
    console.log(data.data)
}).catch(console.log);
axios.get('/now/aqi2/pm2-5').then((data) => {
    this.setState({ nowaqi2Pm25: data.data })
    console.log(data.data)
}).catch(console.log);
axios.get('/now/aqi2/pres').then((data) => {
    this.setState({ nowaqi2Pres: data.data })
    console.log(data.data)
}).catch(console.log);
axios.get('/now/aqi2/tc').then((data) => {
    this.setState({ nowaqi2Tc: data.data })
    console.log(data.data)
}).catch(console.log);
axios.get('/now/aqi2/so2').then((data) => {
    this.setState({ nowaqi2So2: data.data })
    console.log(data.data)
}).catch(console.log);
axios.get('/now/aqi2/hum').then((data) => {
    this.setState({ nowaqi2Hum: data.data })
    console.log(data.data)
}).catch(console.log);


}



      
      share() {
        htmlToImage.toJpeg(document.getElementById('aqiDiv'), { quality: 0.95 })
            .then(function (dataUrl) {
                // console.log(dataUrl);
                // var link = document.createElement('a');
                // link.download = 'my-image-name.jpeg';
                // link.href = dataUrl;
                // link.click();
                // const content = link ;
                const uploadImg = new FormData();
                uploadImg.append('file', dataUrl);
                axios.post(`/aqi/share/`, uploadImg)
                    .then(() => { })
                    .catch();

            },
                window.open("https://www.facebook.com/sharer.php?u=smartport.cacttus.com")
            );
    }

    

 


 componentDidMount() {
    // this.pageReload();
    this.autorefresh();
    window.scrollTo(0, 0);
    AOS.init();
    this.apiCalls();



}
componentWillMount() {
    this.handleTofuChange();
    console.log(this.state.togglebutton, 'toggle');
}

componentWillUnmount() {
    clearInterval(this.interval);
  }

    render() {

      
    const chartPm25 = {
        labels: this.state.graphPm25.map((chart) => (
            chart.timestamp.slice(11, 16)
        )),

        datasets: [{
            label: 'PM 2.5',
            backgroundColor: '#d1d1d1',
            hoverBackgroundColor: '#089000',

            // borderColor: 'rgba(0,0,0,1)',
            // borderWidth: 2,
            data: this.state.graphPm25.map((chart) => (
                chart.value
            ))
        }
        ]

    }
    // console.log(chartPm25);



    const chartPm10 = {
        labels: this.state.graphPm10.map((chart) => (
            chart.timestamp.slice(11, 16)
        )),
        datasets: [{
            label: 'PM 10',
            backgroundColor: '#d1d1d1',
            hoverBackgroundColor: '#089000',
            // borderColor: 'rgba(0,0,0,1)',
            // borderWidth: 2,
            data: this.state.graphPm10.map((chart) => (
                chart.value
            ))
        }]
    }
    // console.log(chartPm10);


    const chartCO2 = {
        labels: this.state.graphCO2.map((chart) => (
            chart.timestamp.slice(11, 16)
        )),
        datasets: [{
            label: 'CO2',
            backgroundColor: '#d1d1d1',
            hoverBackgroundColor: '#089000',
            // borderColor: 'rgba(0,0,0,1)',
            // borderWidth: 2,
            data: this.state.graphCO2.map((chart) => (
                chart.value
            ))
        }]
    }
    // console.log(chartCO2);


    const chartCO = {
        labels: this.state.graphCO.map((chart) => (
            chart.timestamp.slice(11, 16)
        )),
        datasets: [{
            label: 'CO',
            backgroundColor: '#d1d1d1',
            hoverBackgroundColor: '#089000',
            // borderColor: 'rgba(0,0,0,1)',
            // borderWidth: 2,
            data: this.state.graphCO.map((chart) => (
                chart.value
            ))
        }]
    }
    // console.log(chartCO);


    const chartO3 = {
        labels: this.state.graphO3.map((chart) => (
            chart.timestamp.slice(11, 16)
        )),
        datasets: [{
            label: 'O3',
            backgroundColor: '#d1d1d1',
            hoverBackgroundColor: '#089000',
            // borderColor: 'rgba(0,0,0,1)',
            // borderWidth: 2,
            data: this.state.graphO3.map((chart) => (
                chart.value
            ))
        }]
    }
    // console.log(chartO3);


    const chartNO2 = {
        labels: this.state.graphNO2.map((chart) => (
            chart.timestamp.slice(11, 16)
        )),
        datasets: [{
            label: 'NO2',
            backgroundColor: '#d1d1d1',
            hoverBackgroundColor: '#089000',
            // borderColor: 'rgba(0,0,0,1)',
            // borderWidth: 2,
            data: this.state.graphNO2.map((chart) => (
                chart.value
            ))
        }]
    }
    // console.log(chartNO2);

    const chartSO2 = {
        labels: this.state.graphSO2.map((chart) => (
            chart.timestamp.slice(11, 16)
        )),
        datasets: [{
            label: 'SO2',
            backgroundColor: '#d1d1d1',
            hoverBackgroundColor: '#089000',
            // borderColor: 'rgba(0,0,0,1)',
            // borderWidth: 2,
            data: this.state.graphSO2.map((chart) => (
                chart.value
            ))
        }]
    }

        return (
            <React.Fragment>
                  <div className="row pl-2 pr-1 mr-lg-5 ml-lg-5 mt-lg-2  ml-0 mt-md-5 mt-4 ">
                    <div className='col-11 row'>
                   
       <div className='pl-1 pr-2 autoRe'> <a onClick={this.autorefresh} 
    //     data-toggle="tooltip" data-placement="top" 
    //    title={this.state.autoRefresh == false ? 'AutoRefresh every 3 Minutes is On' : 'AutoRefresh is off'} 
       >
           <i className={this.state.autoRefresh == true ? 'fas fa-sync-alt ' : 'fas fa-sync-alt text-success rotating-css'}></i> </a> 
           <div className='autoReLabel'>{this.state.autoRefresh == false ? this.props.label.autorefreshON : this.props.label.autorefreshOFF }</div>
       </div> 
       <div className=''>
                           <Toggle
                            defaultChecked={false}
                            icons={true}
                            onChange={this.handleTofuChange} />
                        <span className="scifiMode"> {this.props.label.scifi}</span>
                        </div>
                    </div>
                    <div className='col-1   justify-content-end pr-lg-2 pr-4 d-flex flex-row-reverse'><a onClick={this.share} className='fbicon' style={{ cursor: 'pointer' }}><img src={fbshare} width='35px' /></a></div>


                </div>


                <AqiHead label={this.props.label} 
                aqi2Pm10={this.state.aqi2Pm10}
                aqi2Pm25={this.state.aqi2Pm25}
                aqiPm25={this.state.aqiPm25}
                aqiPm10={this.state.aqiPm10}
                aqiSo2={this.state.aqiSo2}
                aqiO3={this.state.aqiO3}
                aqiCo2={this.state.aqiCo2}
                aqiCo={this.state.aqiCo}
                aqiNo2={this.state.aqiNo2}
                agrAne={this.state.agrAne}
                agrHum={this.state.agrHum}
                agrPres={this.state.agrPres}
                agrTc={this.state.agrTc}
                agrWv={this.state.agrWv}
                aqi2Tc={this.state.aqi2Tc}
                aqi1Co2={this.state.aqi1Co2}
                aqi1Co={this.state.aqi1Co}
                aqi2O3={this.state.aqi2O3}
                aqi2No2={this.state.aqi2No2}
                aqi2So2={this.state.aqi2So2}
                
                
                />
{ this.state.togglebutton && (
<React.Fragment>
                
                <div className="row pl-lg-5 pr-lg-5 pl-xs-1 pr-xs1 mx-auto justify-content-center" aos-init aos-animate >
                    <div className="col-12 text-center"><h4>{this.props.label.avg_per_hr_today}</h4></div>
                    <div className="col-lg-6 col-12 pt-3 pb-3 mx-auto mt-2 mb-2 rounded bg-light hpGraph">< Bar data={chartPm25} height={100} options={{ maintainAspectRatio: false }} /></div>
                    <div className="col-lg-6 col-12 pt-3 pb-3 mx-auto mt-2 mb-2 rounded bg-light hpGraph">< Bar data={chartPm10} height={100} options={{ maintainAspectRatio: false }} /></div>
                    <div className="col-lg-6 col-12 pt-3 pb-3 mx-auto mt-2 mb-2 rounded bg-light hpGraph">< Bar data={chartO3} height={100} options={{ maintainAspectRatio: false }} /></div>

                    <div className="col-lg-6 col-12 pt-3 pb-3 mx-auto mt-2 mb-2 rounded bg-light hpGraph">< Bar data={chartCO} height={100} options={{ maintainAspectRatio: false }} /></div>
                    <div className="col-lg-6 col-12 pt-3 pb-3 mx-auto mt-2 mb-2 rounded bg-light hpGraph">< Bar data={chartSO2} height={100} options={{ maintainAspectRatio: false }} /></div>
                    <div className="col-lg-6 col-12 pt-3 pb-3 mx-auto mt-2 mb-2 rounded bg-light hpGraph">< Bar data={chartNO2} height={100} options={{ maintainAspectRatio: false }} /></div>



                </div>
            </React.Fragment>
            )}

            { !this.state.togglebutton && <Scientific 
            nowagrAne={this.state.nowagrAne}
            nowagrHum={this.state.nowagrHum}
            nowagrLux={this.state.nowagrLux}
            nowagrPlv1={this.state.nowagrPlv1}
            nowagrPlv2={this.state.nowagrPlv2}
            nowagrPlv3={this.state.nowagrPlv3}
            nowagrPres={this.state.nowagrPres}
            nowagrSoil2={this.state.nowagrSoil2}
            nowagrTc={this.state.nowagrTc}
            nowagrWv={this.state.nowagrWv}
            nowscpCo2={this.state.nowscpCo2}
            nowscpNoise={this.state.nowscpNoise}
            nowscpUs={this.state.nowscpUs}
            nowaqi1Ch4={this.state.nowaqi1Ch4}
            nowaqi1Co={this.state.nowaqi1Co}
            nowaqi1Co2={this.state.nowaqi1Co2}
            nowaqi1Hum={this.state.nowaqi1Hum}
            nowaqi1Pm1={this.state.nowaqi1Pm1}
            nowaqi1Pm10={this.state.nowaqi1Pm10}
            nowaqi1Pm25={this.state.nowaqi1Pm25}
            nowaqi1Pres={this.state.nowaqi1Pres}
            nowaqi1Tc={this.state.nowaqi1Tc}
            nowaqi2Hum={this.state.nowaqi2Hum}
            nowaqi2No2={this.state.nowaqi2No2}
            nowaqi2O3={this.state.nowaqi2O3}
            nowaqi2So2={this.state.nowaqi2So2}
            nowaqi2Pm1={this.state.nowaqi2Pm1}
            nowaqi2Pm10={this.state.nowaqi2Pm10}
            nowaqi2Pm25={this.state.nowaqi2Pm25}
            nowaqi2Pres={this.state.nowaqi2Pres}
            nowaqi2Tc={this.state.nowaqi2Tc}
            nowlocations={this.state.nowlocations}
            nowloc={this.state.nowloc}
            nowwasp={this.state.nowwasp}
            isLoaded={this.state.isLoaded}
            label={this.props.label}
            />}
            </React.Fragment>

        );

    }

}

export default withRouter(Homepage);