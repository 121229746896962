import React, { useState, Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import axios from '../axios';
import AOS from 'aos';
import 'aos/dist/aos.css';
import GreenFace from '../icons/green.svg';
import OrangeFace from '../icons/orange.svg';
import PurpleFace from '../icons/purple.svg';
import RedFace from '../icons/red.svg';
import YellowFace from '../icons/yellow.svg';
import HumIcon from '../icons/humidity.svg';
import htmlToImage from 'html-to-image';
import fbshare from '../icons/fb.png';

class AqiHead extends Component {
  constructor() {
    super()
    AOS.init({
      duration: 500
    });
    this.props = {
      isLoaded: false,
      aqi2Pm10: [],
      aqi2Pm25: [],
      aqiPm25: [],
      aqiPm10: [],
      aqiSo2: [],
      aqiO3: [],
      aqiCo2: [],
      aqiCo: [],
      aqiNo2: [],
      agrAne: [],
      agrHum: [],
      agrPres: [],
      agrTc: [],
      agrWv: [],
      aqi2Tc: [],
      aqi1Co2: [],
      aqi1Co: [],
      aqi2O3: [],
      aqi2No2: [],
      aqi2So2: [],
      uploadImage: null

      // buttonState: false
    }
  }


  componentDidMount() {
    window.scrollTo(0, 0);
    AOS.init();
  }


share () {
  htmlToImage.toJpeg(document.getElementById('aqiDiv'), { quality: 0.95 })
  .then(function (dataUrl) {
    // console.log(dataUrl);
    // var link = document.createElement('a');
    // link.download = 'my-image-name.jpeg';
    // link.href = dataUrl;
    // link.click();
    // const content = link ;
    const uploadImg = new FormData();
    uploadImg.append('file', dataUrl);
    axios.post(`/aqi/share/`, uploadImg)
      .then(() => {})
      .catch();
  
  },
      window.open("https://www.facebook.com/sharer.php?u=smartport.cacttus.com")
);
}



  render() {
    

    return (



      <React.Fragment>

        {this.props.label.air_pollutants == null ? <div className="container"><div className="loader">Loading...</div></div> :





          <div className="row pt-lg-2  pt-md-2 mt-lg-2 mt-md-2 mt-n5 pl-lg-5 pr-lg-5 pb-4 p-1 pt-5 " >
        
            <div   className="col-lg-6 col-12 " >

          
              <div   className="row p-lg-1 p-1  w-sm-100 mt-4 text-light mx-auto rounded justify-content-center aqi-info-header" style={{ backgroundColor: `${this.props.aqiPm25[0] && this.props.aqiPm25[0].Color}` }} >
               <div id='aqiDiv' className='col-lg-4 col-6' >
                 <div className= 'row' style={{ backgroundColor: `${this.props.aqiPm25[0] && this.props.aqiPm25[0].Color}` }}>
                <div className='col-lg-6 col-6 aqiFace'   >
                  {/* { this.props.aqi2Pm10.value < this.props.aqi2Pm25.value ?  this.props.aqiPm25[0] && this.props.aqiPm25[0].sensValue : this.props.aqiPm10[0] && this.props.aqiPm10[0].sensValue} */}
                  {(this.props.aqiPm25[0] && this.props.aqiPm25[0].AQIcategory) == 'Good' ? <img src={GreenFace} alt="aqiFace" calssName="aqiFaceImg "></img> : ''}
                  {(this.props.aqiPm25[0] && this.props.aqiPm25[0].AQIcategory) == 'Moderate' ? <img src={YellowFace} alt="aqiFace" calssName="aqiFaceImg"></img> : ''}
                  {(this.props.aqiPm25[0] && this.props.aqiPm25[0].AQIcategory) == 'Unhealthy for Sensitive Groups' ? <img src={OrangeFace} alt="aqiFace" calssName="aqiFaceImg"></img> : ''}
                  {(this.props.aqiPm25[0] && this.props.aqiPm25[0].AQIcategory) == 'Unhealthy' ? <img src={RedFace} alt="aqiFace" calssName="aqiFaceImg"></img> : ''}
                  {(this.props.aqiPm25[0] && this.props.aqiPm25[0].AQIcategory) == 'Very Unhealthy' ? <img src={PurpleFace} alt="aqiFace" calssName="aqiFaceImg"></img> : ''}
                  {(this.props.aqiPm25[0] && this.props.aqiPm25[0].AQIcategory) == 'Hazardous' ? <img src={PurpleFace} alt="aqiFace" calssName="aqiFaceImg"></img> : ''}

                </div>
                <div className='col-lg-6 col-6 aqiInfoValue text-center' > US AQI  <h4 className="text-light">  {this.props.aqiPm25[0] && this.props.aqiPm25[0].value}</h4></div>
                </div>
                </div>
                <div className='col-lg-6 col-6 aqiInfo' > {this.props.label.aqi_quality + ': '}
                  <h4 className="text-light">
                    {(this.props.aqiPm25[0] && this.props.aqiPm25[0].AQIcategory) == 'Good' ? this.props.label.aqi_cat_1 : ''}
                    {(this.props.aqiPm25[0] && this.props.aqiPm25[0].AQIcategory) == 'Moderate' ? this.props.label.aqi_cat_2 : ''}
                    {(this.props.aqiPm25[0] && this.props.aqiPm25[0].AQIcategory) == 'Unhealthy for Sensitive Groups' ? this.props.label.aqi_cat_3 : ''}
                    {(this.props.aqiPm25[0] && this.props.aqiPm25[0].AQIcategory) == 'Unhealthy' ? this.props.label.aqi_cat_4 : ''}
                    {(this.props.aqiPm25[0] && this.props.aqiPm25[0].AQIcategory) == 'Very Unhealthy' ? this.props.label.aqi_cat_5 : ''}
                    {(this.props.aqiPm25[0] && this.props.aqiPm25[0].AQIcategory) == 'Hazardous' ? this.props.label.aqi_cat_6 : ''}
                  </h4>
                  <br />
                  <p className="text-light">
                    {this.props.label.aqi_health_impact + ': '}
                    {(this.props.aqiPm25[0] && this.props.aqiPm25[0].AQIcategory) == 'Good' ? this.props.label.aqi_hi_1 : ''}
                    {(this.props.aqiPm25[0] && this.props.aqiPm25[0].AQIcategory) == 'Moderate' ? this.props.label.aqi_hi_2 : ''}
                    {(this.props.aqiPm25[0] && this.props.aqiPm25[0].AQIcategory) == 'Unhealthy for Sensitive Groups' ? this.props.label.aqi_hi_3 : ''}
                    {(this.props.aqiPm25[0] && this.props.aqiPm25[0].AQIcategory) == 'Unhealthy' ? this.props.label.aqi_hi_4 : ''}
                    {(this.props.aqiPm25[0] && this.props.aqiPm25[0].AQIcategory) == 'Very Unhealthy' ? this.props.label.aqi_hi_5 : ''}
                    {(this.props.aqiPm25[0] && this.props.aqiPm25[0].AQIcategory) == 'Hazardous' ? this.props.label.aqi_hi_6 : ''}
                  <div>  {this.props.label.last_update + ": " + (this.props.aqi2Pm25.timestamp && this.props.aqi2Pm25.timestamp.slice(0,5)) }</div>
                  </p>
                 
                  </div>
         
              </div>
              <div className="row weatherBox mt-2 p-2  text-dark bg-light mx-auto" >
                <div className="col-lg-3 col-md-3 col-6"><i class="fas fa-thermometer-half"></i> {(this.props.aqi2Tc.value == 'undefined' || this.props.aqi2Tc.value == null) ? 'N/A' : ' ' + (this.props.aqi2Tc.value.slice(0, 3) + ' ºC')}</div>
                <div className="col-lg-3 col-md-3 col-6"><img src={HumIcon} alt="humIcon" width="17"></img> {(this.props.agrHum.value == 'undefined' || this.props.agrHum.value == null) ? 'N/A' : ' ' + (this.props.agrHum.value.slice(0, 3)) + ' %RH'} </div>
                <div className="col-lg-3 col-md-3 col-6"><i className="fas fa-compass"></i> {(this.props.agrWv.value == 'undefined' || this.props.agrWv.value == null) ? 'N/A' : ' ' + (this.props.agrWv.value)}</div>
                <div className="col-lg-3 col-md-3 col-6"><i className="fas fa-wind"></i> {(this.props.agrAne.value == 'undefined' || this.props.agrAne.value == null) ? 'N/A' : ' ' + (this.props.agrAne.value.slice(0, 3)) + ' km/h'} </div>

              </div>


            </div>

            <div className="col-lg-6 airPolutantsMain "  >
              <h4 className="">{this.props.label.air_pollutants}</h4>
              <div className="row ">
                <NavLink to='/pm25/AQI02' className="aqiPolutants" style={{ borderColor: `${this.props.aqiPm25[0] && this.props.aqiPm25[0].Color}` }}   >

                  <span className="emri">PM 2.5</span> <br /> <h2 style={{ color: `${this.props.aqiPm25[0] && this.props.aqiPm25[0].Color}` }}  >{this.props.aqi2Pm25.value == null ? 'N/A' : this.props.aqi2Pm25.value.slice(0, 5)} </h2> <span className="vlera">μg/m3</span>
                </NavLink>
                <NavLink to='/pm10/AQI02' className="aqiPolutants" style={{ borderColor: `${this.props.aqiPm10[0] && this.props.aqiPm10[0].Color}` }}  >
                  <span className="emri">PM 10 </span><br /> <h2 style={{ color: `${this.props.aqiPm10[0] && this.props.aqiPm10[0].Color}` }}>{this.props.aqi2Pm10.value == null ? 'N/A' : this.props.aqi2Pm10.value.slice(0, 5)}</h2 > <span className="vlera">μg/m3</span>
                </NavLink>

                <NavLink to='/o3/AQI02' className="aqiPolutants" style={{ borderColor: `${this.props.aqiO3[0] && this.props.aqiO3[0].Color}` }}  >
                  <span className="emri">O3</span> <br /> <h2 style={{ color: `${this.props.aqiO3[0] && this.props.aqiO3[0].Color}` }}>{this.props.aqi2O3.value == null ? 'N/A' : this.props.aqi2O3.value.slice(0, 5)}</h2><span className="vlera">ppm</span>
                </NavLink>

                <NavLink to='/co/AQI01' className="aqiPolutants" style={{ borderColor: `${this.props.aqiCo[0] && this.props.aqiCo[0].Color}` }}  >
                  <span className="emri">CO</span><br /> <h2 style={{ color: `${this.props.aqiCo[0] && this.props.aqiCo[0].Color}` }}>{this.props.aqi1Co.value == null ? 'N/A' : this.props.aqi1Co.value.slice(0, 5)}</h2><span className="vlera">ppm</span>
                </NavLink>

                <NavLink to='/so2/AQI02' className="aqiPolutants" style={{ borderColor: `${this.props.aqiSo2[0] && this.props.aqiSo2[0].Color}` }}  >
                  <span className="emri">SO2</span> <br /> <h2 style={{ color: `${this.props.aqiSo2[0] && this.props.aqiSo2[0].Color}` }}>{this.props.aqi2So2.value == null || this.props.aqi2So2.value == 'undefined' ? 'N/A' : this.props.aqi2So2.value.slice(0, 5)}</h2><span className="vlera">ppm</span>
                </NavLink>


                <NavLink to='/no2/AQI02' className="aqiPolutants" style={{ borderColor: `${this.props.aqiNo2[0] && this.props.aqiNo2[0].Color}` }}  >
                  <span className="emri">NO2</span> <br /> <h2 style={{ color: `${this.props.aqiNo2[0] && this.props.aqiNo2[0].Color}` }}>{this.props.aqi2No2.value == null ? 'N/A' : this.props.aqi2No2.value.slice(0, 5)}</h2><span className="vlera">ppm</span>
                </NavLink>

              </div>
            </div>

          </div>
        }

      </React.Fragment>

    );

  }

}

export default withRouter(AqiHead);