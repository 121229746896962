import React, { useEffect, useState, Fragment } from 'react';
import './Labels.scss';
import axios from './../axios';
import { withRouter } from 'react-router-dom';

const Labels = (props) => {

    const [ labels, addLabels ] = useState([]);
    const [ label, setLabel ] = useState({});
    const [ newLabel, setNewLabel ] = useState({});
    const [ row, setRow ] = useState(0);
    const [ edit, setEdit ] = useState(false);
    // 

    useEffect(() => {
        if(localStorage?.getItem('token')){
            // window.location.reload();
            axios.get('label')
                .then(res => addLabels(res.data))
                .catch(err => console.log(err))
        }
        else
        {
            props.history.push('/login/123-fa/ss');
        }
    }, [])

    const LblName = (e) => {
        let datatoadd = Object.assign({}, label);
        datatoadd.LblName = e.target.value
        setLabel(datatoadd)
    };

    const LblEN = (e) => {
        let datatoadd = Object.assign({}, label);
        datatoadd.LblEN = e.target.value
        setLabel(datatoadd)
    };
    
    const LblAL = (e) => {
        let datatoadd = Object.assign({}, label);
        datatoadd.LblAL = e.target.value
        setLabel(datatoadd)
    };

    const getLabelsAgain = () => {
        axios.get('label')
            .then(res => addLabels(res.data))
            .catch(err => console.log(err))
    }

    const saveLabel = () => {
        // if(labels.LblName && labels.LblEN && labels.LblAL){
            axios.post('/label', label)
                .then((res) => {
                    if(res.data.error){
                        alert('Label with this name exists');
                    }
                    else
                    {
                        getLabelsAgain();
                        setLabel({});
                        setEdit(false);
                    }
                })
                .catch(err => console.log(err))
        // }
    }
    
    const deleteLabel = (id) => {
        axios.delete('/label',  { data: { id: id }} )
            .then(() => getLabelsAgain())
            .catch(err => console.log(err))
    }

    const newLblName = (e, id) => {
        let labelsData = [...labels];
        labelsData[id].LblName = e.target.value;
        addLabels(labelsData);
    };

    const newLblEN = (e, id) => {
        let labelsData = [...labels];
        labelsData[id].LblEN = e.target.value;
        addLabels(labelsData);
    };
    
    const newLblAL = (e, id) => {
        let labelsData = [...labels];
        labelsData[id].LblAL = e.target.value;
        addLabels(labelsData);
    };

    const saveEdit = (index) => {
        axios.put('/label', labels[index])
            .then((res) => {
                getLabelsAgain();
                setLabel({});
                setEdit(false);
                setRow(0);
            })
            .catch(err => console.log(err))
    }

    return (
        <div className="container-fluid ">
            <div className="row p-5">
                <div className="col-lg-12">
                <section>
                  <h1 className="p-3">LABEL NAMING IN LIBELIUM</h1>
                  <div className="tbl-header">
                    <table cellpadding="0" cellspacing="0" border="0" className="table bg-light align-left rounded">
                      <thead>
                        <tr>
                          <th>NR</th>
                          <th>Label Name</th>
                          <th>NAME EN	</th>
                          <th>NAME SQ	</th>
                          <th><button className="add" onClick = { () => setEdit(true) }>Add new</button></th>
                        </tr>
                      </thead>
    
                      <tbody>
                        {
                                labels.map((el, index) => {
                                    return (
                                    <tr key={ index }>
                                        <td>{ index + 1 }</td>
                                        {
                                            row !== index + 1 ?
                                            <Fragment>
                                                <td className="data title">{ el.LblName }</td>
                                                <td className="data">{ el.LblEN }</td>
                                                <td className="data">{ el.LblAL }</td>
                                            </Fragment>
                                            :
                                            <Fragment>
                                                <td className="data"><input type="text" onChange = { (e) => newLblName(e, index) } value={ el.LblName } /></td>
                                                <td className="data"><input type="text" onChange = { (e) => newLblEN(e, index) } value={ el.LblEN }/></td>
                                                <td className="data"><input type="text" onChange = { (e) => newLblAL(e, index) } value={ el.LblAL }/></td>
                                            </Fragment>
                                        }
                                        <td>
                                            { row == index + 1 && <button className="save" onClick = { () => saveEdit(index) }>Save</button> }
                                            { row !== index + 1 && <button className="edit" onClick = { () => setRow(index + 1) }>Edit</button> }
                                            <button className="delete" onClick = { () => deleteLabel(el.id) }>Delete</button>
                                        </td>
                                    </tr>
                                    )
                                })
                            }
                           {
                               edit &&
                                <tr>
                                    <td className="data"><input type="text" onChange = { LblName }/></td>
                                    <td className="data"><input type="text" onChange = { LblEN }/></td>
                                    <td className="data"><input type="text" onChange = { LblAL }/></td>
                                    <td>
                                        <button className="save" onClick = { saveLabel }>Save</button>
                                        <button className="delete" onClick = { () => setEdit(false) }>Cancel</button>
                                    </td>
                                </tr>
                           }
                      </tbody>
                    </table>
                  </div>
                </section>
                </div>   
            </div>
        </div>
    );
};

export default withRouter(Labels);