import Particles from 'react-particles-js';
import React, { useState, Component } from 'react';
import {NavLink} from 'react-router-dom';
import './../App.css';
 
class SensorBubble extends Component {
  
    render(){
        return (
            <Particles 
            
            params={{
                width: "100%;",
                height: "100%;",
                "particles": {
                    "number": {
                        "value": 7,
                        "density": {
                            "enable": true,
                            "value_area": 800
                        }
                    },
                    "line_linked": {
                        "enable": true
                    },
                    "move": {
                        "speed": 1,
                        "out_mode": "out"
                    },
                    "shape": {
                        "type": [
                            "images",
                            "circle"
                        ],
                        "images": [
                            {
                                "src": "/smartlogo.png",
                                "height": 40,
                                "width": 223
                            },
                
                        ]
                    },
                    "color": {
                        "value": "#089000"
                    },
                    "size": {
                        "value": 150,
                        "random": false,
                        "anim": {
                            "enable": true,
                            "speed": 4,
                            "size_min": 10,
                            "sync": false
                        }
                    }
                },
                "retina_detect": false
            }} />
        );
    };
 
}
export default SensorBubble;