import Axios from 'axios';
let token = window.localStorage.getItem('token');

const axiosInstance = Axios.create({
    // baseURL: 'http://localhost:5001/api/',
    baseURL: 'https://smartapi.cacttus.com/api/',
    headers: {
        Authorization: 'Bearer ' + (token !== null && token),
      },
    
});

export default axiosInstance;