import React, { Component } from 'react';
import { Collapse, Button } from 'reactstrap';
import { Route, withRouter, NavLink, Redirect, Switch } from 'react-router-dom';
import './App.css';
import Login from './Login/Login';
import axios from './axios';
// import Particles from 'react-particles-js';
// import SideNavi from './components/sideNavi';
// import Homepage from './components/homepage';
// import SampleSensor from './components/sampleSensor';
import SensHum from './sensorPages/sensHum';
import SensBat from './sensorPages/sensBat';
import SensPres from './sensorPages/sensPres';
import SensLux from './sensorPages/sensLux';
import SensLc from './sensorPages/sensTc';
import SensPm1 from './sensorPages/sensPm1';
import SensPm10 from './sensorPages/sensPm10';
import SensPm25 from './sensorPages/sensPm25';
import SensPlv1 from './sensorPages/sensPlv1';
import SensPlv2 from './sensorPages/sensPlv2';
import SensPlv3 from './sensorPages/sensPlv3';
import SensSoil2 from './sensorPages/sensSoil2';
import SensAne from './sensorPages/sensAne';
import SensUs from './sensorPages/sensUs';
import SensCo2 from './sensorPages/sensCo2';
import SensCo from './sensorPages/sensCo';
import SensCh4 from './sensorPages/sensCh4';
import SensNoise from './sensorPages/sensNoise';
import SensNo2 from './sensorPages/sensNo2';
import SensO3 from './sensorPages/sensO3';
import SensSo2 from './sensorPages/sensSo2';
import SensWv from './sensorPages/sensWv';
import SensHeader from './components/header';
import SensFooter from './components/footer';
import SensorBubble from './components/sensorBubble';
import Labels from './Labels/Labels';
// import HomepageStatic from './components/homepageStatic';
import HomepageStaticHeader from './components/hpStaticHeader';
import Homepage from './components/homepage';
import Batteries from './sensorPages/batteries';
import AboutPage from './components/about';
import Auth from './Auth';

// import { Collapse, Button, CardBody, Card } from 'reactstrap';




class App extends Component {

  state = {
    isOpen: false,
  }

  toggle = () => this.setState({ isOpen: !this.state.isOpen });
  // logOut = () => {
  //   localStorage.removeItem('t13');
  // }

  logout (e) {
    // e.preventDefault();
    window.localStorage.removeItem('token');
    window.location.href="./";
   
      }

  componentDidMount() {
    let lang = window.localStorage.getItem('lang');

    if (lang) {
      axios.get(`/label/${lang}`)
        .then(res => this.setState(res.data))
        .catch(err => console.log(err))
    }
    else {
      axios.get(`/label/en`)
        .then(res => this.setState(res.data))
        .catch(err => console.log(err))
    }
  }



  render() {


    return (

      <div className="App ">
        <div className="app-background w-100"> <SensorBubble /> </div>
        <div className='fixed-nav  bg-light p-2 mb-3 box-shadow-dn'>
          <div className="row w-100">
            <SensHeader />
            <div className='col-lg-1  col-2  menuBtnDiv '>

              <Button className="btn-main rounded text-light " onClick={this.toggle}>
                {this.state.isOpen !== false ?
                  <i class="fa fa-times fa-2x "></i>
                  : <i class="fa fa-bars fa-2x "></i>
                }
              </Button>

            </div>
          </div>
        </div>

        <div className=" app-front w-100 ">
              
          <Collapse isOpen={this.state.isOpen}>
            <div className="lib-navLinks shadow rounded">
              <ul>
                <NavLink to="/" onClick={this.toggle}> <li className="w-100 p-2 text-secondary shadow"> <i className="fa fa-home"> </i> Home </li></NavLink>
                <NavLink to="/about" onClick={this.toggle}> <li className="w-100 p-2 text-secondary shadow"> <i className="fa fa-info-circle"> </i> About </li></NavLink>
                {Auth() ?
                  <React.Fragment>
                    <NavLink to="/batteries" onClick={this.toggle}> <li className="w-100 p-2 text-secondary shadow"> <i className="fa fa-battery"> </i> Batteries </li></NavLink>
                    <NavLink to="/labels" onClick={this.toggle}> <li className="w-100 p-2 text-secondary shadow"> <i className="fa fa-language"> </i> Translations </li></NavLink>
                    <button onClick={this.logout} className="w-100 p-0 m-0"> <li className="w-100 p-2 text-secondary shadow"> <i className="fas fa-sign-out-alt"></i> Log Out </li></button>
                    
                  </React.Fragment>
                  :
                  <React.Fragment>
                    <NavLink to="/login" onClick={this.toggle}> <li className="w-100 p-2 text-secondary shadow"> <i className="fa fa-sign-in-alt"> </i> Log In </li></NavLink>
                  </React.Fragment>
                }
              </ul>

            </div>
          </Collapse>
          <Switch>
            <React.Fragment>
              <Route path="/login" exact render={() => <Login label={this.state} />} />
              <Route path="/bat/:wasp" exact render={() => <SensBat label={this.state} />} />
              <Route path="/hum/:wasp" exact render={() => <SensHum label={this.state}  />} />
              <Route path="/pres/:wasp" exact render={() => <SensPres label={this.state} />} />
              <Route path="/lux/:wasp" exact render={() => <SensLux label={this.state} />} />
              <Route path="/tc/:wasp" exact render={() => <SensLc label={this.state} />} />
              <Route path="/plv1/:wasp" exact render={() => <SensPlv1 label={this.state} />} />
              <Route path="/plv2/:wasp" exact render={() => <SensPlv2 label={this.state} />} />
              <Route path="/plv3/:wasp" exact render={() => <SensPlv3 label={this.state} />} />
              <Route path="/soil2/:wasp" exact render={() => <SensSoil2 label={this.state} />} />
              <Route path="/ane/:wasp" exact render={() => <SensAne label={this.state} />} />
              <Route path="/wv/:wasp" exact render={() => <SensWv label={this.state} />} />
              <Route path="/us/:wasp" exact render={() => <SensUs label={this.state} />} />
              <Route path="/co2/:wasp" exact render={() => <SensCo2 label={this.state} />} />
              <Route path="/ch4/:wasp" exact render={() => <SensCh4 label={this.state} />} />
              <Route path="/co/:wasp" exact render={() => <SensCo label={this.state} />} />
              <Route path="/noise/:wasp" exact render={() => <SensNoise label={this.state} />} />
              <Route path="/no2/:wasp" exact render={() => <SensNo2 label={this.state} />} />
              <Route path="/o3/:wasp" exact render={() => <SensO3 label={this.state} />} />
              <Route path="/so2/:wasp" exact render={() => <SensSo2 label={this.state} />} />
              <Route path="/pm1/:wasp" exact render={() => <SensPm1 label={this.state} />} />
              <Route path="/pm25/:wasp" exact render={() => <SensPm25 label={this.state} />} />
              <Route path="/pm10/:wasp" exact render={() => <SensPm10 label={this.state} />} />
              <Route path="/scientific" exact render={() => <HomepageStaticHeader label={this.state} />} />
              <Route path="/about" exact render={() => <AboutPage label={this.state} />} />
              <Route path="/" exact render={() => <Homepage label={this.state} />} />

          
            {Auth() &&
              <React.Fragment>
              <Route path="/labels" exact render={() => <Labels label={this.state} />} />
              <Route path="/batteries" exact render={() => <Batteries label={this.state} />} /> 
              </React.Fragment> 

              
            }

</React.Fragment>

          </Switch>


          <SensFooter />
        </div>
      </div>
    );
  }
}
export default withRouter(App);
