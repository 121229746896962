function isAuthenticated(){
    
    let Token = window.localStorage.getItem('token');
    if(Token)
        return true;
    else
        return false
    }
    
    
    export default isAuthenticated;