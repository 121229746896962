import React, { useState, Component } from 'react';
import {NavLink} from 'react-router-dom';
import { Collapse, Button, CardBody, Card, CardHeader , Table} from 'reactstrap';
import axios from '../axios';
import AOS from 'aos';
import 'aos/dist/aos.css';

class Batteries extends Component {
  constructor() {
    super()
    AOS.init({
      duration : 500
     });
    this.state = {
        isOpen : false,
        agrBat: [],
        scpBat: [],
        aqi1Bat: [],
        aqi2Bat: [],
        locations: [],
        loc: [],
        wasp: []
    }
  }

 toggle = () => {
  this.setState({isOpen: true} );

}


componentDidMount () { window.scrollTo(0,0);
  AOS.init();
  axios.get('/locations')
  // .then(res => res.json())
   .then((data) => {
       this.setState({ locations: [data.data[0]] }) 
      //  console.log(data.data)
   }
   )

   axios.get('/locations')
   // .then(res => res.json())
    .then((data) => {
        this.setState({ loc: data.data }) 
       //  console.log(data.data)
    }
    )


   axios.get('/wasp')
   // .then(res => res.json())
    .then((data) => {
        this.setState({ wasp: data.data }) 
        // console.log(data.data)
    }
    )
 
  axios.get('/now/agr/bat').then((data) => {this.setState({ agrBat: data.data }) 
  console.log(data.data)}).catch(console.log);

  axios.get('/now/scp/bat').then((data) => {this.setState({ scpBat: data.data }) 
  console.log(data.data)}).catch(console.log);

  axios.get('/now/aqi1/bat').then((data) => {this.setState({ aqi1Bat: data.data }) 
  console.log(data.data)}).catch(console.log);

  axios.get('/now/aqi2/bat').then((data) => {this.setState({ aqi2Bat: data.data }) 
  console.log(data.data)}).catch(console.log);



}

 

render () {
 


  return (
    <React.Fragment>

   <div className="row mt-lg-3 mt-5 p-3" aos-init aos-animate >
   {
        this.state.wasp.map((wasp) => (
        
    <div className="col-lg-6 col-sm-12 "  data-aos="flip-right" data-aos-easing="linear" >
        <div className="libelium-box p-3" >
         <h3> {wasp.id_wasp == 'ADKGEN_AGR' ? this.props.label.smart_agr_pro : wasp.id_wasp
                   && wasp.id_wasp == 'ADKGEN_SCP' ? this.props.label.smart_city_pro : wasp.id_wasp 
                   && wasp.id_wasp == 'AQI01' ? this.props.label.smart_env_pro_d1 : wasp.id_wasp
                   && wasp.id_wasp == 'AQI02' ? this.props.label.smart_env_pro_d2 : wasp.id_wasp}</h3> 
                  
            
 
       
         {this.state.locations.map((locations) => (
             <div className="row">
    {this.state.loc.map((loc) => (
    <div className="col-12 col-sm-12 text-left">
        {wasp.MeshliumID == loc.MeshliumID ? `${this.props.label.location}: ${loc.LongDSC} ` : ''}
      </div> 
    ))}
     <div className="col-12 col-sm-12 text-left pb-2 box-shadow-dn">
     {wasp.id_wasp == 'ADKGEN_AGR' && ( `${this.props.label.last_update}: ${this.state.agrBat[0] && this.state.agrBat[0].timestamp}` )}
     {wasp.id_wasp == 'ADKGEN_SCP' && ( `${this.props.label.last_update}: ${this.state.scpBat[0] && this.state.scpBat[0].timestamp}` )}
     {wasp.id_wasp == 'AQI01' && ( `${this.props.label.last_update}: ${this.state.aqi1Bat[0] && this.state.aqi1Bat[0].timestamp}` )}
     {wasp.id_wasp == 'AQI02' && ( `${this.props.label.last_update}: ${this.state.aqi2Bat[0] && this.state.aqi2Bat[0].timestamp}` )}
             
      </div> 
     
   <div className="w-100 ">
      {wasp.id_wasp == 'ADKGEN_AGR' ? (
            <div className="row w-100 main-hover ml-0  ">
                <div className="col-lg-8 col-6 p-2 text-right align-middle ">Battery </div>
                <div className= "col-lg-4 col-6 p-2 bg-main-1 text-w  text-left align-middle"> {this.state.agrBat[0] && this.state.agrBat[0].value  + ' %'} </div>
            </div>

 )  : ''}


{wasp.id_wasp == 'ADKGEN_SCP' ? (
         <div className="row w-100 main-hover ml-0  ">
             <div className="col-lg-8 col-6 p-2 text-right align-middle ">Battery </div>
             <div className= "col-lg-4 col-6 p-2 bg-main-1 text-w  text-left align-middle">  {this.state.scpBat[0] && this.state.scpBat[0].value  + ' %'} </div>
         </div>
)  : ''}



{wasp.id_wasp == 'AQI01' ? (
         <div className="row w-100 main-hover ml-0  ">
             <div className="col-lg-8 col-6 p-2 text-right align-middle ">Battery </div>
             <div className= "col-lg-4 col-6 p-2 bg-main-1 text-w  text-left align-middle"> {this.state.aqi1Bat[0] && this.state.aqi1Bat[0].value  + ' %'} </div>
         </div>
)  : ''}



{wasp.id_wasp == 'AQI02' ? ( 
         <div className="row w-100 main-hover ml-0  ">
             <div className="col-lg-8 col-6 p-2 text-right align-middle ">Battery </div>
             <div className= "col-lg-4 col-6 p-2 bg-main-1 text-w  text-left align-middle"> {this.state.aqi2Bat[0] && this.state.aqi2Bat[0].value + ' %'} </div>
         </div>
)  : ''}



</div>
      </div>
         )) }
         
         
     </div>
     </div>

 
 ))} 

</div>
</React.Fragment>

  );

}

}

export default Batteries;