import React, { useState, Component } from 'react';
import { Collapse, Button, CardBody, Card, CardHeader , Table} from 'reactstrap';
import axios from '../axios';
import {Bar, Line} from 'react-chartjs-2';
import { withRouter } from 'react-router-dom';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import AOS from 'aos';
import 'aos/dist/aos.css';

class SensHum extends Component {
    constructor() {
        super()
    var today = new Date(),
    dateNow = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    AOS.init({
        duration : 2000
       });

 this.state = {
    isOpen : false,
    sensData: [],
    thisMonthAvg: [],
    dataLog: [],
    loc:[],
    wasploc: [],
    locNow: [],
    fromDate: `${dateNow}`,
    toDate: `${dateNow}`,
    wasp: []

 }
}


 toggle = () => {
  this.setState({isOpen: true} );

}
setLocation = () => {
    this.setState({locNow: this.state.wasploc.find((el, index)=>{
        return el.id_wasp == this.props.match.params.wasp}).LocationName
   
    })
}

// findLocation = () => {
//     this.setState({locNow: this.state.wasploc.find((el, index)=>{
//         return el.id_wasp == this.props.match.params.wasp
//     }).LocationName } );
  
//   }

fromDate = (e) => {
    this.setState({fromDate: e.target.value})
}


toDate = (e) => {
    this.setState({toDate: e.target.value})
}


findByDate = (e) => {
    axios.get(`/date/?sen=HUM&from=` + this.state.fromDate +`&to=`+ this.state.toDate +`&loc=`+ this.state.locNow )
    // .then(res => res.json())
     .then((data) => {
         this.setState({ dataLog: data.data }) 
         console.log(data.data)
     }
     )
     .catch(console.log);  
}


componentDidMount () { window.scrollTo(0,0);
    

    this.findByDate();
    axios.get('/wasploc')
    // .then(res => res.json())
     .then((data) => {
         this.setState({ wasploc: data.data }) 
        //  console.log(data.data)
        this.setLocation();
        this.findByDate();
     }
     )

    axios.get('/locations')
    // .then(res => res.json())
     .then((data) => {
         this.setState({ loc: data.data }) 
        //  console.log(data.data)
        
     }
     )


    
 
    axios.get('/wasp')
    // .then(res => res.json())
     .then((data) => {
         this.setState({ wasp: data.data }) 
         // console.log(data.data)
     }
     )

 console.log(this.props.match.params.wasp)

  axios.get(`/now/` + ( this.props.match.params.wasp == 'ADKGEN_AGR' ? 'agr' : this.props.match.params.wasp == 'ADKGEN_SCP' ? 'scp' : this.props.match.params.wasp == 'AQI01' ? 'aqi1' : this.props.match.params.wasp == 'AQI02' ? 'aqi2': '' ) + `/hum`)
  // .then(res => res.json())
   .then((data) => {
       this.setState({ sensData: data.data }) 
       console.log(data.data)
   }
   )
   .catch(console.log);

  
  
   console.log(this.props.match.params.wasp)
   axios.get(`/monthavg/now/?wasp=` + this.props.match.params.wasp + `&sen=HUM`)
   // .then(res => res.json())
    .then((data) => {
        this.setState({ thisMonthAvg: data.data }) 
        console.log(data.data)
    }
    )
    .catch(console.log);


    console.log(this.props.match.params.wasp)

    

}




componentDidUpdate() {
    // this.findByDate();
}


render () {
    const chartData = {
        labels:  this.state.thisMonthAvg.map((chart) => (
            chart.timestamp
        )),
        datasets: [ {
            label: 'Value',
             backgroundColor: '#d1d1d1',
            hoverBackgroundColor: '#089000',
            // borderColor: 'rgba(0,0,0,1)',
            // borderWidth: 2,
            data:  this.state.thisMonthAvg.map((chart) => (
                chart.value
            ))
        } ]
    }
    console.log(chartData);

    const chartDataLog = {
        labels:  this.state.dataLog.map((chart) => (
            chart.timestamp
        )),
        datasets: [ {
            label: 'Value',
             backgroundColor: '#d1d1d1',
            hoverBackgroundColor: '#089000',
            // borderColor: 'rgba(0,0,0,1)',
            // borderWidth: 2,
            data:  this.state.dataLog.map((chart) => (
                chart.value
            ))
        } ]
    }
    console.log(chartDataLog);

    return (
        <div class="containter-fluid ">


            <div className="row  p-5    text-left" aos-init aos-animate >



                <div className="col-lg-6 col-sm-12 p-3 " data-aos="fade-up" ><h1>
                    {
                        this.state.sensData.sensor == 'BAT' ? 'Battery' : this.state.sensData.sensor
                            && this.state.sensData.sensor == 'WV' ? this.props.label.wind_vane : this.state.sensData.sensor
                            && this.state.sensData.sensor == 'HUM' ? this.props.label.humidity : this.state.sensData.sensor
                            && this.state.sensData.sensor == 'PRES' ? this.props.label.pressure : this.state.sensData.sensor
                            && this.state.sensData.sensor == 'LUX' ? this.props.label.luminosity : this.state.sensData.sensor
                            && this.state.sensData.sensor == 'TC' ? this.props.label.temperature : this.state.sensData.sensor
                            && this.state.sensData.sensor == 'PLV1' ? this.props.label.plv1 : this.state.sensData.sensor
                            && this.state.sensData.sensor == 'PLV2' ? this.props.label.plv2 : this.state.sensData.sensor
                            && this.state.sensData.sensor == 'PLV3' ? this.props.label.plv3 : this.state.sensData.sensor
                            && this.state.sensData.sensor == 'SOIL2' ? this.props.label.soil_mosture_watermark2 : this.state.sensData.sensor
                            && this.state.sensData.sensor == 'ANE' ? this.props.label.anemometer : this.state.sensData.sensor
                            && this.state.sensData.sensor == 'US' ? this.props.label.ultrasound : this.state.sensData.sensor
                            && this.state.sensData.sensor == 'CO2' ? this.props.label.carbon_dioxide : this.state.sensData.sensor
                            && this.state.sensData.sensor == 'CH4' ? this.props.label.methane : this.state.sensData.sensor
                            && this.state.sensData.sensor == 'CO' ? this.props.label.carbon_monoxide : this.state.sensData.sensor
                            && this.state.sensData.sensor == 'NOISE' ? this.props.label.noise_level : this.state.sensData.sensor
                            && this.state.sensData.sensor == 'NO2' ? this.props.label.nitrogen_dioxide : this.state.sensData.sensor
                            && this.state.sensData.sensor == 'O3' ? this.props.label.ozone : this.state.sensData.sensor
                            && this.state.sensData.sensor == 'SO2' ? this.props.label.sulfur_dioxide : this.state.sensData.sensor
                            && this.state.sensData.sensor == 'PM1' ? this.props.label.particle_matter_1 : this.state.sensData.sensor
                            && this.state.sensData.sensor == 'PM2_5' ? this.props.label.particle_matter_25 : this.state.sensData.sensor
                            && this.state.sensData.sensor == 'PM10' ? this.props.label.particle_matter_10 : this.state.sensData.sensor

                    } {this.props.label.now}
                </h1> <br />
                </div>


                <div className='col-lg-3 col-sm-12 rounded bg-main text-light p-3 sensValue ' data-aos="fade-left">
                    <h1>
                        {
                            this.state.sensData.sensor == 'HUM' && this.state.sensData.value.slice(0, 5)
                        }
                    </h1>
                    <br />
                    <p className="sensMeasure">
                        {
                            this.state.sensData.sensor == 'BAT' ? `%` : this.state.sensData.sensor
                                && this.state.sensData.sensor == 'WV' ? `Direction` : this.state.sensData.value
                                    && this.state.sensData.sensor == 'HUM' ? `%RH` : this.state.sensData.value
                                        && this.state.sensData.sensor == 'PRES' ? `Pascales` : this.state.sensData.value
                                            && this.state.sensData.sensor == 'LUX' ? `luxes` : this.state.sensData.value
                                                && this.state.sensData.sensor == 'TC' ? `ºC` : this.state.sensData.value
                                                    && this.state.sensData.sensor == 'PLV1' ? `mm` : this.state.sensData.value
                                                        && this.state.sensData.sensor == 'PLV2' ? `mm/h` : this.state.sensData.value
                                                            && this.state.sensData.sensor == 'PLV3' ? `mm/day` : this.state.sensData.value
                                                                && this.state.sensData.sensor == 'SOIL2' ? `Frequency` : this.state.sensData.value
                                                                    && this.state.sensData.sensor == 'ANE' ? `km/h` : this.state.sensData.value
                                                                        && this.state.sensData.sensor == 'US' ? `cm` : this.state.sensData.value
                                                                            && this.state.sensData.sensor == 'CO2' ? `ppm` : this.state.sensData.value
                                                                                && this.state.sensData.sensor == 'CH4' ? `% LEL` : this.state.sensData.value
                                                                                    && this.state.sensData.sensor == 'CO' ? `ppm` : this.state.sensData.value
                                                                                        && this.state.sensData.sensor == 'NOISE' ? `dBA` : this.state.sensData.value
                                                                                            && this.state.sensData.sensor == 'NO2' ? `ppm` : this.state.sensData.value
                                                                                                && this.state.sensData.sensor == 'O3' ? `ppm` : this.state.sensData.value
                                                                                                    && this.state.sensData.sensor == 'SO2' ? `ppm` : this.state.sensData.value
                                                                                                        && this.state.sensData.sensor == 'PM1' ? `μg/m3` : this.state.sensData.value
                                                                                                            && this.state.sensData.sensor == 'PM2_5' ? `μg/m3` : this.state.sensData.value
                                                                                                                && this.state.sensData.sensor == 'PM10' ? `μg/m3` : this.state.sensData.value
                        }

                    </p>
                </div>
                <div className='col-lg-3 col-sm-12 rounded bg-light text-secondary p-3 sensInfo ' data-aos="fade-right">

                    {this.props.label.location}: {this.state.loc.map((lokacioni) => (
                        this.state.sensData.MeshliumID == lokacioni.MeshliumID && lokacioni.LongDSC
                    ))}<br />
                    {this.props.label.module}:  {this.state.sensData.id_wasp == 'ADKGEN_AGR' ? this.props.label.smart_agr_pro : this.state.sensData.id_wasp
                        && this.state.sensData.id_wasp == 'ADKGEN_SCP' ? this.props.label.smart_city_pro : this.state.sensData.id_wasp
                            && this.state.sensData.id_wasp == 'AQI01' ? this.props.label.smart_env_pro_d1 : this.state.sensData.id_wasp
                                && this.state.sensData.id_wasp == 'AQI02' ? this.props.label.smart_env_pro_d2 : this.state.sensData.id_wasp} <br />
                    {this.props.label.last_update}: {this.state.sensData.sensor == 'HUM' && this.state.sensData.timestamp}<br />

                </div>

            </div>
            {/* Div Head */}


            <div className='row p-3   bg-light' aos-init aos-animate data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration="1500" >
                <div className='col-lg-6  col-sm-12 p-3 pr-5 pl-5'>
                    <h3> {this.props.label.avg_dai_val_f_c_m}:</h3>
                    < Bar data={chartData} />
                </div>
                <div className='col-lg-6  col-sm-12 p-3 libelium-box height-box-1 overflow-auto p-3 mx-auto text-center'>
                    <h3> {this.props.label.avg_dai_val_f_c_m}:</h3>
                    <Table>
                        <thead>
                            <tr>
                                <th>{this.props.label.value}</th>
                                <th>{this.props.label.date}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.thisMonthAvg.map((thisMonthAvg) => (


                                <tr>
                                    <td>{thisMonthAvg.value}</td>
                                    <td>{thisMonthAvg.timestamp}</td>
                                </tr>


                            ))}
                        </tbody>
                    </Table>

                </div>

            </div>
            <div className='row  ' aos-init aos-animate data-aos="fade-down"
                data-aos-easing="linear"
                data-aos-duration="1000">
                <div className=" col-lg-12  col-sm-12  p-3 text-center">
                    <h3>{this.props.label.data_log} </h3>
                    <br />
                    {this.props.label.sel_from_date_to_date}

                </div>

            </div>
            <div className="row bg-light justify-content-center pt-3 pb-2 p-2">
                <div className=' col-lg-1 col-sm-6 text-lg-right text-xs-left p-2 pl-3'>
                    {this.props.label.from_date + ": "}
                </div>
                <div className=' col-lg-3 col-sm-6 justify-content-left '>
                    <input type='date' className='form-control' onChange={this.fromDate} />
                </div>
                <div className=' col-lg-1 col-sm-6 text-lg-right ext-xs-left p-2 pl-3'>
                    {this.props.label.to_date + ':  '}
                </div>
                <div className='col-lg-3 col-sm-6 mb-3 '>
                    <input type='date' className='form-control' onChange={this.toDate} />
                </div>
                <div className="pt-xs-3">
                <button className="btn-primary btn-main rounded p-2 " onClick={this.findByDate}>{this.props.label.get_results} </button>
                </div>
            </div>
            <div className='row bg-light  p-4' aos-init aos-animate data-aos="fade-down"
                data-aos-easing="linear"
                data-aos-duration="1000" >
                <div className='col-lg-7 d-lg-inline d-none bg-light col-sm-12 p-3 mx-auto p-2 '>
                    < Bar data={chartDataLog} />
                </div>
                {/* <div className="col-2"></div> */}
                <div className='col-lg-5 col-sm-12 libelium-box height-box-1 overflow-auto p-4 ml-n4 mx-auto'>
                    <ReactHTMLTableToExcel
                        id="test-table-xls-button"
                        className="btn-primary btn-main p-2 rounded mb-2"
                        table="sensor-data"
                        filename="sensorData"
                        sheet="HUM"
                        buttonText={this.props.label.download_as_xls} />
                    <Table id="sensor-data">
                        <thead>
                            <tr>
                                <th>{this.props.label.sensor_value} </th>
                                <th>{this.props.label.date}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.dataLog.map((dataLog) => (


                                <tr>
                                    <td>{dataLog.value}</td>
                                    <td>{dataLog.timestamp}</td>
                                </tr>


                            ))}
                        </tbody>
                    </Table>
                </div>

            </div>


        </div> /*  Last Div */
    );

}

}
export default withRouter(SensHum);