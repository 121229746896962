import React, { useState, Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';



class Scientific extends Component {
    constructor() {
        super()
        AOS.init({
            duration: 500
        });
        this.state = {
            isOpen: false,

            isLoaded: false
        }
    }



  
    render() {



        return (
            <React.Fragment>

                {/* {this.props.isLoaded ? null : <div className="container"><div className="loader">Loading...</div></div>} */}

                <div className="row pl-lg-5 pr-lg-5 pl-3 pr-1" aos-init aos-animate >
                    {
                        this.props.nowwasp.map((nowwasp) => (

                            <div className="col-lg-6 col-sm-12 " data-aos="flip-right" data-aos-easing="linear" >
                                <div className="libelium-box p-3" >
                                    <h3> {nowwasp.id_wasp == 'ADKGEN_AGR' ? this.props.label.smart_agr_pro : nowwasp.id_wasp
                                        && nowwasp.id_wasp == 'ADKGEN_SCP' ? this.props.label.smart_city_pro : nowwasp.id_wasp
                                            && nowwasp.id_wasp == 'AQI01' ? this.props.label.smart_env_pro_d1 : nowwasp.id_wasp
                                                && nowwasp.id_wasp == 'AQI02' ? this.props.label.smart_env_pro_d2 : nowwasp.id_wasp}</h3>

                                    {this.props.nowlocations.map((nowlocations) => (
                                        <div className="row">
                                            {this.props.nowloc.map((loc) => (
                                                <div className="col-12 col-sm-12 text-left">
                                                    {nowwasp.MeshliumID == loc.MeshliumID ? `${this.props.label.location}: ${loc.LongDSC} ` : ''}
                                                </div>
                                            ))}
                                            <div className="col-12 col-sm-12 text-left pb-2 box-shadow-dn">
                                                {nowwasp.id_wasp == 'ADKGEN_AGR' && (`${this.props.label.last_update}: ${this.props.nowagrAne.timestamp && this.props.nowagrAne.timestamp.slice(0,5)}`)}
                                                {nowwasp.id_wasp == 'ADKGEN_SCP' && (`${this.props.label.last_update}: ${this.props.nowscpUs.timestamp  && this.props.nowscpUs.timestamp.slice(0,5)}`)}
                                                {nowwasp.id_wasp == 'AQI01' && (`${this.props.label.last_update}: ${this.props.nowaqi1Tc.timestamp  && this.props.nowaqi1Tc.timestamp.slice(0,5)}`)}
                                                {nowwasp.id_wasp == 'AQI02' && (`${this.props.label.last_update}: ${this.props.nowaqi2Tc.timestamp  && this.props.nowaqi2Tc.timestamp.slice(0,5)}`)}

                                            </div>

                                            <div className="w-100 height-box overflow-auto">
                                                {nowwasp.id_wasp == 'ADKGEN_AGR' ? (


                                                    <React.Fragment>

                                                        <NavLink to={'tc/ADKGEN_AGR'} >
                                                            <div className="row w-100 main-hover ml-0  ">
                                                                <div className="col-lg-8 col-6 p-2 text-right align-middle ">   {this.props.label.temperature} </div>
                                                                <div className="col-lg-4 col-6 p-2 bg-secondary text-light  text-left align-middle">  {(this.props.nowagrTc.value == 'undefined' || this.props.nowagrTc.value == null) ? 'N/A' : (this.props.nowagrTc.value.slice(0, 5) + ' ºC')} </div>
                                                            </div>
                                                        </NavLink>

                                                        <NavLink to={'wv/ADKGEN_AGR'} >
                                                            <div className="row w-100 main-hover ml-0  ">
                                                                <div className="col-lg-8 col-6 p-2 text-right align-middle ">   {this.props.label.wind_vane} </div>
                                                                <div className="col-lg-4 col-6 p-2 bg-secondary text-light  text-left align-middle">  {this.props.nowagrWv.value.slice(0, 5) + ' Direction'} </div>
                                                            </div>
                                                        </NavLink>

                                                        <NavLink to={'ane/ADKGEN_AGR'} >
                                                            <div className="row w-100 main-hover ml-0  ">
                                                                <div className="col-lg-8 col-6 p-2 text-right align-middle "> {this.props.label.anemometer}</div>
                                                                <div className="col-lg-4 col-6 p-2 bg-secondary text-light  text-left align-middle"> {this.props.nowagrAne.value.slice(0, 5) + ' km/h'} </div>
                                                            </div>
                                                        </NavLink>

                                                        <NavLink to={'hum/ADKGEN_AGR'} >
                                                            <div className="row w-100 main-hover ml-0  ">
                                                                <div className="col-lg-8 col-6 p-2 text-right align-middle "> {this.props.label.humidity}</div>
                                                                <div className="col-lg-4 col-6 p-2 bg-secondary text-light  text-left align-middle">  {this.props.nowagrHum.value.slice(0, 5) + ' %RH'} </div>
                                                            </div>
                                                        </NavLink>

                                                        <NavLink to={'lux/ADKGEN_AGR'} >
                                                            <div className="row w-100 main-hover ml-0  ">
                                                                <div className="col-lg-8 col-6 p-2 text-right align-middle "> {this.props.label.luminosity}</div>
                                                                <div className="col-lg-4 col-6 p-2 bg-secondary text-light  text-left align-middle">  {this.props.nowagrLux.value.slice(0, 5) + ' luxes'} </div>
                                                            </div>
                                                        </NavLink>

                                                        <NavLink to={'plv1/ADKGEN_AGR'} >
                                                            <div className="row w-100 main-hover ml-0  ">
                                                                <div className="col-lg-8 col-6 p-2 text-right align-middle "> {this.props.label.pluviometer} {this.props.label.current_hour}</div>
                                                                <div className="col-lg-4 col-6 p-2 bg-secondary text-light  text-left align-middle">  {this.props.nowagrPlv1.value.slice(0, 5) + ' mm'} </div>
                                                            </div>
                                                        </NavLink>

                                                        <NavLink to={'plv2/ADKGEN_AGR'} >
                                                            <div className="row w-100 main-hover ml-0  ">
                                                                <div className="col-lg-8 col-6 p-2 text-right align-middle "> {this.props.label.pluviometer} {this.props.label.previous_hour}</div>
                                                                <div className="col-lg-4 col-6 p-2 bg-secondary text-light  text-left align-middle">  {this.props.nowagrPlv2.value.slice(0, 5) + ' mm/h'} </div>
                                                            </div>
                                                        </NavLink>

                                                        <NavLink to={'plv3/ADKGEN_AGR'} >
                                                            <div className="row w-100 main-hover ml-0  ">
                                                                <div className="col-lg-8 col-6 p-2 text-right align-middle "> {this.props.label.pluviometer} {this.props.label.last_24}</div>
                                                                <div className="col-lg-4 col-6 p-2 bg-secondary text-light  text-left align-middle">  {this.props.nowagrPlv3.value.slice(0, 5) + ' mm/day'} </div>
                                                            </div>
                                                        </NavLink>

                                                        <NavLink to={'pres/ADKGEN_AGR'} >
                                                            <div className="row w-100 main-hover ml-0  ">
                                                                <div className="col-lg-8 col-6 p-2 text-right align-middle ">   {this.props.label.pressure}</div>
                                                                <div className="col-lg-4 col-6 p-2 bg-secondary text-light  text-left align-middle">  {this.props.nowagrPres.value.slice(0, 5) + ' Pascales'} </div>
                                                            </div>
                                                        </NavLink>

                                                        <NavLink to={'soil2/ADKGEN_AGR'} >
                                                            <div className="row w-100 main-hover ml-0  ">
                                                                <div className="col-lg-8 col-6 p-2 text-right align-middle "> {this.props.label.soil_mosture_watermark2} </div>
                                                                <div className="col-lg-4 col-6 p-2 bg-secondary text-light  text-left align-middle">  {this.props.nowagrSoil2.value.slice(0, 5) + ' Frequency'} </div>
                                                            </div>
                                                        </NavLink>







                                                    </React.Fragment>
                                                ) : ''}


                                                {nowwasp.id_wasp == 'ADKGEN_SCP' ? (


                                                    <React.Fragment>

                                                        <NavLink to={'co2/ADKGEN_SCP'} >
                                                            <div className="row w-100 main-hover ml-0  ">
                                                                <div className="col-lg-8 col-6 p-2 text-right align-middle ">   {this.props.label.carbon_dioxide} </div>
                                                                <div className="col-lg-4 col-6 p-2 bg-secondary text-light  text-left align-middle">  {(this.props.nowscpCo2.value == 'undefined' || this.props.nowscpCo2.value == null) ? 'N/A' : (this.props.nowscpCo2.value.slice(0, 5) + ' ppm')} </div>
                                                            </div>
                                                        </NavLink>

                                                        <NavLink to={'noise/ADKGEN_SCP'} >
                                                            <div className="row w-100 main-hover ml-0  ">
                                                                <div className="col-lg-8 col-6 p-2 text-right align-middle ">   {this.props.label.noise_level} </div>
                                                                <div className="col-lg-4 col-6 p-2 bg-secondary text-light  text-left align-middle">  {this.props.nowscpNoise.value.slice(0, 5) + ' dBA'} </div>
                                                            </div>
                                                        </NavLink>

                                                        <NavLink to={'us/ADKGEN_SCP'} >
                                                            <div className="row w-100 main-hover ml-0  ">
                                                                <div className="col-lg-8 col-6 p-2 text-right align-middle "> {this.props.label.ultrasound}</div>
                                                                <div className="col-lg-4 col-6 p-2 bg-secondary text-light  text-left align-middle"> {this.props.nowscpUs.value.slice(0, 5) + ' cm'} </div>
                                                            </div>
                                                        </NavLink>

                                                    </React.Fragment>
                                                ) : ''}



                                                {nowwasp.id_wasp == 'AQI01' ? (


                                                    <React.Fragment>

                                                        <NavLink to={'ch4/AQI01'} >
                                                            <div className="row w-100 main-hover ml-0  ">
                                                                <div className="col-lg-8 col-6 p-2 text-right align-middle ">   {this.props.label.methane} </div>
                                                                <div className="col-lg-4 col-6 p-2 bg-secondary text-light  text-left align-middle">  {(this.props.nowaqi1Ch4.value == 'undefined' || this.props.nowaqi1Ch4.value == null) ? 'N/A' : (this.props.nowaqi1Ch4.value.slice(0, 5) + ' % LEL')} </div>
                                                            </div>
                                                        </NavLink>

                                                        <NavLink to={'co/AQI01'} >
                                                            <div className="row w-100 main-hover ml-0  ">
                                                                <div className="col-lg-8 col-6 p-2 text-right align-middle ">   {this.props.label.carbon_monoxide} </div>
                                                                <div className="col-lg-4 col-6 p-2 bg-secondary text-light  text-left align-middle">  {(this.props.nowaqi1Co.value == 'undefined' || this.props.nowaqi1Co.value == null) ? 'N/A' : (this.props.nowaqi1Co.value.slice(0, 5) + ' ppm')} </div>
                                                            </div>
                                                        </NavLink>

                                                        <NavLink to={'co2/AQI01'} >
                                                            <div className="row w-100 main-hover ml-0  ">
                                                                <div className="col-lg-8 col-6 p-2 text-right align-middle "> {this.props.label.carbon_dioxide}</div>
                                                                <div className="col-lg-4 col-6 p-2 bg-secondary text-light  text-left align-middle"> {(this.props.nowaqi1Co2.value == 'undefined' || this.props.nowaqi1Co2.value == null) ? 'N/A' : (this.props.nowaqi1Co2.value.slice(0, 5) + ' ppm')} </div>
                                                            </div>
                                                        </NavLink>

                                                        <NavLink to={'hum/AQI01'} >
                                                            <div className="row w-100 main-hover ml-0  ">
                                                                <div className="col-lg-8 col-6 p-2 text-right align-middle "> {this.props.label.humidity}</div>
                                                                <div className="col-lg-4 col-6 p-2 bg-secondary text-light  text-left align-middle">  {(this.props.nowaqi1Hum.value == 'undefined' || this.props.nowaqi1Hum.value == null) ? 'N/A' : (this.props.nowaqi1Hum.value.slice(0, 5) + ' %RH')} </div>
                                                            </div>
                                                        </NavLink>

                                                        <NavLink to={'pres/AQI01'} >
                                                            <div className="row w-100 main-hover ml-0  ">
                                                                <div className="col-lg-8 col-6 p-2 text-right align-middle "> {this.props.label.pressure} {this.props.label.last_24}</div>
                                                                <div className="col-lg-4 col-6 p-2 bg-secondary text-light  text-left align-middle">  {(this.props.nowaqi1Pres.value == 'undefined' || this.props.nowaqi1Pres.value == null) ? 'N/A' : (this.props.nowaqi1Pres.value.slice(0, 5) + ' Pascales')} </div>
                                                            </div>
                                                        </NavLink>

                                                        <NavLink to={'tc/AQI01'} >
                                                            <div className="row w-100 main-hover ml-0  ">
                                                                <div className="col-lg-8 col-6 p-2 text-right align-middle ">   {this.props.label.temperature}</div>
                                                                <div className="col-lg-4 col-6 p-2 bg-secondary text-light  text-left align-middle">  {(this.props.nowaqi1Tc.value == 'undefined' || this.props.nowaqi1Tc.value == null) ? 'N/A' : (this.props.nowaqi1Tc.value.slice(0, 5) + ' ºC')} </div>
                                                            </div>
                                                        </NavLink>

                                                    </React.Fragment>
                                                ) : ''}



                                                {nowwasp.id_wasp == 'AQI02' ? (


                                                    <React.Fragment>

                                                        <NavLink to={'hum/AQI02'} >
                                                            <div className="row w-100 main-hover ml-0  ">
                                                                <div className="col-lg-8 col-6 p-2 text-right align-middle "> {this.props.label.humidity}</div>
                                                                <div className="col-lg-4 col-6 p-2 bg-secondary text-light  text-left align-middle">  {(this.props.nowaqi2Hum.value == 'undefined' || this.props.nowaqi2Hum.value == null) ? 'N/A' : (this.props.nowaqi2Hum.value.slice(0, 5) + ' %RH')} </div>
                                                            </div>
                                                        </NavLink>

                                                        <NavLink to={'no2/AQI02'} >
                                                            <div className="row w-100 main-hover ml-0  ">
                                                                <div className="col-lg-8 col-6 p-2 text-right align-middle ">   {this.props.label.nitrogen_dioxide} </div>
                                                                <div className="col-lg-4 col-6 p-2 bg-secondary text-light  text-left align-middle">  {(this.props.nowaqi2No2.value == 'undefined' || this.props.nowaqi2No2.value == null) ? 'N/A' : (this.props.nowaqi2No2.value.slice(0, 5) + ' ppm')} </div>
                                                            </div>
                                                        </NavLink>

                                                        <NavLink to={'o3/AQI02'} >
                                                            <div className="row w-100 main-hover ml-0  ">
                                                                <div className="col-lg-8 col-6 p-2 text-right align-middle ">   {this.props.label.ozone} </div>
                                                                <div className="col-lg-4 col-6 p-2 bg-secondary text-light  text-left align-middle">  {(this.props.nowaqi2O3.value == 'undefined' || this.props.nowaqi2O3.value == null) ? 'N/A' : (this.props.nowaqi2O3.value.slice(0, 5) + ' ppm')} </div>
                                                            </div>
                                                        </NavLink>

                                                        <NavLink to={'pm1/AQI02'} >
                                                            <div className="row w-100 main-hover ml-0  ">
                                                                <div className="col-lg-8 col-6 p-2 text-right align-middle "> {this.props.label.particle_matter_1}</div>
                                                                <div className="col-lg-4 col-6 p-2 bg-secondary text-light  text-left align-middle">  {(this.props.nowaqi2Pm1.value == 'undefined' || this.props.nowaqi2Pm1 == null) ? 'N/A' : (this.props.nowaqi2Pm1.value.slice(0, 5) + ' μg/m3')} </div>
                                                            </div>
                                                        </NavLink>

                                                        <NavLink to={'pm10/AQI02'} >
                                                            <div className="row w-100 main-hover ml-0  ">
                                                                <div className="col-lg-8 col-6 p-2 text-right align-middle "> {this.props.label.particle_matter_10}</div>
                                                                <div className="col-lg-4 col-6 p-2 bg-secondary text-light  text-left align-middle"> {(this.props.nowaqi2Pm10.value == 'undefined' || this.props.nowaqi2Pm10 == null) ? 'N/A' : (this.props.nowaqi2Pm10.value.slice(0, 5) + ' μg/m3')}</div>
                                                            </div>
                                                        </NavLink>

                                                        <NavLink to={'pm25/AQI02'} >
                                                            <div className="row w-100 main-hover ml-0  ">
                                                                <div className="col-lg-8 col-6 p-2 text-right align-middle "> {this.props.label.particle_matter_25} </div>
                                                                <div className="col-lg-4 col-6 p-2 bg-secondary text-light  text-left align-middle">  {(this.props.nowaqi2Pm25.value == 'undefined' || this.props.nowaqi2Pm25 == null) ? 'N/A' : (this.props.nowaqi2Pm25.value.slice(0, 5) + ' μg/m3')} </div>
                                                            </div>
                                                        </NavLink>

                                                        <NavLink to={'so2/AQI02'} >
                                                            <div className="row w-100 main-hover ml-0  ">
                                                                <div className="col-lg-8 col-6 p-2 text-right align-middle "> {this.props.label.sulfur_dioxide}</div>
                                                                <div className="col-lg-4 col-6 p-2 bg-secondary text-light  text-left align-middle"> {(this.props.nowaqi2So2.value == 'undefined' || this.props.nowaqi2So2.value == null) ? 'N/A' : (this.props.nowaqi2So2.value.slice(0, 5) + ' ppm')} </div>
                                                            </div>
                                                        </NavLink>

                                                        <NavLink to={'pres/AQI02'} >
                                                            <div className="row w-100 main-hover ml-0  ">
                                                                <div className="col-lg-8 col-6 p-2 text-right align-middle "> {this.props.label.pressure}</div>
                                                                <div className="col-lg-4 col-6 p-2 bg-secondary text-light  text-left align-middle">  {(this.props.nowaqi2Pres.value == 'undefined' || this.props.nowaqi2Pres.value == null) ? 'N/A' : (this.props.nowaqi2Pres.value.slice(0, 5) + ' Pascales')} </div>
                                                            </div>
                                                        </NavLink>

                                                        <NavLink to={'tc/AQI02'} >
                                                            <div className="row w-100 main-hover ml-0  ">
                                                                <div className="col-lg-8 col-6 p-2 text-right align-middle ">   {this.props.label.temperature}</div>
                                                                <div className="col-lg-4 col-6 p-2 bg-secondary text-light  text-left align-middle">  {(this.props.nowaqi2Tc.value == 'undefined' || this.props.nowaqi2Tc.value == null) ? 'N/A' : (this.props.nowaqi2Tc.value.slice(0, 3) + ' ºC')} </div>
                                                            </div>
                                                        </NavLink>


                                                    </React.Fragment>
                                                ) : ''}



                                            </div>
                                        </div>
                                    ))}


                                </div>
                            </div>


                        ))}

                </div>
            </React.Fragment>

        );

    }

}

export default withRouter(Scientific);