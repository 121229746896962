import React, { useState, Component } from 'react';
import {NavLink, withRouter} from 'react-router-dom';
import axios from '../axios';
import AOS from 'aos';
import 'aos/dist/aos.css';


class AboutPage extends Component {
  constructor() {
    super()
    AOS.init({
      duration : 500
     });
    this.state = {

      
    }
  }


componentDidMount () { 
  window.scrollTo(0,0);
  AOS.init();

}


render () {
 
  

  return (
    <React.Fragment>


<div className="row pt-lg-2  pt-md-2 mt-lg-2 mt-md-2 mt-n5 pl-lg-5 pr-lg-5 pb-4 p-1 pt-5 ">
    <div className="row w-100"> <h1>About</h1></div>
    <div className="row w-100"><h4>Under Construction..</h4></div>

</div>

</React.Fragment>

  );

}

}

export default withRouter(AboutPage);