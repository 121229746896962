import React, { Component } from 'react';
import {NavLink, withRouter} from 'react-router-dom';
// import Logo from './logo.png';
// import SideNav from 'react-side-nav';
class SensHeader extends Component {

setLanguage = (lang) => {
  window.localStorage.setItem('lang', lang);
  window.location.reload();
}

render () {

    return (
     
      <React.Fragment>
            <div className="lang">
              <button className={ window.localStorage.getItem('lang') == 'en' && 'btn_active' } onClick = { () => this.setLanguage('en') }>EN</button>
              <button className={ window.localStorage.getItem('lang') == 'al' && 'btn_active' } onClick = { () => this.setLanguage('al') }>SQ</button>
            </div>
            <div className='col-lg-10 col-10 pl-lg-5 pl-4' ><NavLink to="/"> <img src={'/smartlogo.png'} className='header-img' alt="Logo" /></NavLink></div>
      </React.Fragment>

    );
  

}

}
export default withRouter(SensHeader);
